import { Component } from '@angular/core';

@Component({
  selector: 'sebu-order-comments',
  template: `
    <h1>Auftragskommentare</h1>

    <sebu-comment-logic
      [apiEndpoint]="apiEndpoint"
    ></sebu-comment-logic>
  `,
  styles: [],
  standalone: false,
})
export class OrderCommentsComponent {
  public apiEndpoint = 'auftrag-kommentar-vorschlag';

}
