export interface Category {
  name: string;
  parentId: string | null | undefined;
  sortOrder: number;
  description: string;
  publishedAt: string | Date;
  is_visible: boolean;
}

export const CATEGORY_ENDPOINT = '/presentation/categories';
