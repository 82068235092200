import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'im-addable-text-list',
  templateUrl: './addable-text-list.component.html',
  styleUrls: ['./addable-text-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddableTextListComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class AddableTextListComponent implements ControlValueAccessor {
  @Input() public disabled = false;
  @Input() public editable = true;

  /** Wert des Textfeldes */
  public inputValue = '';
  /** @private */
  public _values: Array<any> = [];

  /** Werte aus der Liste. Stellt den konkreten Wert dieser Komponente dar */
  public get values() {
    return this.inputValue.trim() === ''
      ? [...this._values]
      : [...this._values, this.beforeAdd(this.inputValue)];
  }

  public set values(values: Array<any>) {
    this._values = values;
  }

  /** Erlaubt es, den Wert vorm Hinzufügen weiter zu transformieren */
  @Input() public beforeAdd: (value: any) => any = x => x;
  /** Anzeigelogik für Item in der Liste */
  @Input() public display: (value: any) => any = x => x;

  _onInputChange() {
    this.onChange(this.values);
  }

  public add(toAdd: any) {
    this._values.push(this.beforeAdd(toAdd));
    this.inputValue = '';
    this.onChange(this._values);
  }

  public remove(index: number) {
    this._values.splice(index, 1);
    this.onChange(this._values);
  }

  get shouldRenderList() {
    return Array.isArray(this._values) && this._values.length > 0;
  }

  get isEmpty() {
    return Array.isArray(this.values) && this.values.length === 0;
  }

  public onChange = (newValues: Array<any>) => {
  }
  public onTouched = (newValues: Array<any>) => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.values = obj || [];
    this.inputValue = '';
    this.onChange(this.values);
  }
}
