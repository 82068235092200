import { Component, Input, OnInit } from '@angular/core';
import { ApiClient } from '../../../common/api/ApiClient';
import { AppSetting, BACKEND_SETTINGS_ENDPOINT} from './models/AppSetting';
import { FromDB } from '../../../common/api/FromDB';
import { ToastrService } from 'ngx-toastr';

/**
 * Komponente zum Verwalten der Anwendungsweiten Einstelllungen durch den Superadmin.
 */
@Component({
  selector: 'im-backend-settings',
  templateUrl: './backend-settings.component.html',
  standalone: false,
})
export class BackendSettingsComponent implements OnInit {
  /** Die Einstellungen aus der Datenbank */
  settings: FromDB<AppSetting>[] = [];

  @Input()
  endpoint = BACKEND_SETTINGS_ENDPOINT;

  @Input()
  title = '';

  constructor(
    private api: ApiClient,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    this.api
      .all<AppSetting>(this.endpoint)
      .subscribe(settings => this.settings = settings);
  }

  updateSetting(setting: FromDB<AppSetting>) {
    this.api
      .update(this.endpoint, setting.Id, setting)
      .subscribe(() => {
        this.toastr.success('Einstellung erfolgreich aktualisiert!');
      });
  }
}
