
import {throwError as observableThrowError } from 'rxjs';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

export const LOADING_ERROR_EVENT =
  'LoadingErrorContainerComponent:loading-error';

@Component({
  selector: 'im-loading-error-container',
  templateUrl: './loading-error-container.component.html',
  styleUrls: ['./loading-error-container.component.scss'],
  standalone: false,
})
export class LoadingErrorContainerComponent implements OnInit, OnDestroy {
  hasError = false;

  constructor(private elRef: ElementRef) {
    this.onLoadingError = this.onLoadingError.bind(this);
  }

  ngOnInit() {
    (this.elRef.nativeElement as HTMLElement).addEventListener(
      LOADING_ERROR_EVENT,
      this.onLoadingError,
    );
  }

  ngOnDestroy() {
    (this.elRef.nativeElement as HTMLElement).removeEventListener(
      LOADING_ERROR_EVENT,
      this.onLoadingError,
    );
  }

  onLoadingError(event: CustomEvent) {
    event.stopPropagation();
    this.hasError = true;
  }
}

/**
 * Löst ein Ladefehler aus, der den DOM-Baum hochbubbled, bis er von einem
 * <loading-error-container> verarbeitet wird.
 *
 * Die Referenz auf ein ELement kann im constructor einer Komponente erhalten
 * werden:
 * ```js
 * constructor(private elRef: ElementRef) {}
 * ```
 *
 * @param {ElementRef} ref
 * @param err
 */
export function emitLoadingError(ref: ElementRef, err: any) {
  (ref.nativeElement as HTMLElement).dispatchEvent(
    new CustomEvent(LOADING_ERROR_EVENT, {
      detail: err,
      bubbles: true,
    }),
  );

  return observableThrowError(err);
}
