import { Component, forwardRef, Input } from '@angular/core';
import { SearchComponent } from '../../../common/forms/inputs/search/search-component';
import { ApiClient } from '../../../common/api/ApiClient';
import autobind from 'autobind-decorator';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sebu-article-search',
  template: `
    <input type="text" class="form-control"
       [(ngModel)]="value"
       (input)="changed($event)"
       [ngbTypeahead]="search"
       [resultFormatter]="formatter"
       [inputFormatter]="formatter"
       (selectItem)="onSelect($event)"
       [ngClass]="inputNgClass"
    />
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ArticleSearchComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class ArticleSearchComponent extends SearchComponent {
  public endpoint = '/artikel/search';

  @Input()
  public inputNgClass = {};

  constructor(protected api: ApiClient) {
    super(api);
  }

  @autobind
  onSelect(event: NgbTypeaheadSelectItemEvent): void {
    this.writeValue(event.item);
  }

  formatter(x: {Bezeichnung1: string, Artikelnummer: number} | undefined) {
    if (x === undefined) {
      return '';
    } else if (typeof x === 'string') {
      return x; // Fehlermeldung / Nachricht das nichts gefunden wurde
    }

    return `${x.Bezeichnung1} (${x.Artikelnummer})`;
  }
}
