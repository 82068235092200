import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {ARTICLE_SUGGESTIONS_ENDPOINT, ArtikelVorschlagWithArtikel} from '../models/ArtikelVorschlag';
import { BaseForm } from '../../../common/forms/base-form';
import {
  AbstractControl,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ArticleSuggestionsService } from './suggestions.service'
import autobind from "autobind-decorator";
import {FromDB} from "../../../common/api/FromDB";
import {DataTableService} from "../../../common/api/data-table/data-table.service";

@Component({
  selector: 'sebu-article-suggestions',
  template: `
    <h1 class="page-title">Artikelvorschläge</h1>
      <form [formGroup]="form" (submit)="onSubmit()">
          <div class="form-group">
            <label>Artikel</label>
            <sebu-article-search
              id="artikel"
              [inputNgClass]="displayFieldCss('artikel')"
              [formControl]="artikelToAdd"
            ></sebu-article-search>
            <small class="form-text text-muted">
              Es kann nach dem Bezeichnung und nach der Artikelnummer gesucht werden.
            </small>
            <im-field-error-display
              [displayError]="submitAttempt && artikelToAdd?.invalid"
              errorMsg="Bitte wählen Sie einen Artikel aus den Suchergebnissen!"
            ></im-field-error-display>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary">
                <im-icon name="plus"></im-icon>
                Hinzufügen
            </button>
          </div>
      </form>
    <im-data-table [endpoint]="endpoint">
      <ng-template imDataTableHeaders>
        <th>Nummer</th>
        <th>Bezeichnung</th>
        <th width="200px">Aktionen</th>
      </ng-template>

      <ng-template imDataTableRow let-row>
        <td>{{ row.artikel.Artikelnummer }}</td>
        <td>{{ row.artikel.Bezeichnung1 }}</td>
        <td>
          <div class="btn-group">
            <button
              imDeleteButton
              [delete]="deleteSuggestion"
              [model]="row"
              [endpoint]="endpoint"
            >
              Entfernen
            </button>
          </div>
        </td>
      </ng-template>
    </im-data-table>
  `,
  styles: [],
  standalone: false,
})
export class ArticleSuggestionsComponent extends BaseForm implements OnInit {
  endpoint = ARTICLE_SUGGESTIONS_ENDPOINT;

  @Output()
  public attemptedSubmit = new EventEmitter();

  @Output()
  public created = new EventEmitter();

  constructor(
      public suggestionService: ArticleSuggestionsService,
      public toastr: ToastrService,
      protected dataTable: DataTableService,
  ) {
      super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      artikelToAdd : new FormControl(null, (control: AbstractControl): {[key: string]: any} | null => {
        return control.value && control.value.Id
          ? null
          : {'artikelToAdd': 'Bitte wählen Sie einen Artikel aus den Suchergebnissen!'};
      }),
    });
  }

  public onSubmit() {
    super.onSubmit();

    if (this.form.valid) {
      if (this.form.value.artikelToAdd.artikel_vorschlag_count){
        this.toastr.warning('Artikel ist schon auf der Liste!');
        return;
      }
      this.suggestionService.create({artikelId: this.form.value.artikelToAdd.Id}).subscribe(() => {
        this.form.value.artikelToAdd.artikel_vorschlag_count = 1;
        this.toastr.success('Erfolgreich hinzugefügt!');
        this.dataTable.reload();
      });
    } else {
      this.validateAllFormFields(this.form);
      this.attemptedSubmit.emit();
    }
  }

  @autobind
  deleteSuggestion(_: string, suggestion: FromDB<ArtikelVorschlagWithArtikel>) {
    if (confirm('Wirklich löschen?')) {
      // @ts-ignore
      this.suggestionService.remove({suggestionId: suggestion.Id})
        .subscribe(() => {
          this.toastr.success('Erfolgreich entfernt!');
          this.dataTable.reload();
        });
    }
  }

  public get artikelToAdd() {
    return this.form.get('artikelToAdd');
  }
}
