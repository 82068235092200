import { SettingComponent } from './setting.component';
import { Component, EventEmitter, Input } from '@angular/core';
import { AppSetting } from '../models/AppSetting';
import { FromDB } from '../../../../common/api/FromDB';

export interface IntegerAppSetting extends AppSetting {
  type: 'integer';
}

/**
 * Komponente zum verwalten eines Boolean-Wertes.
 */
@Component({
  selector: 'im-integer-setting',
  template: `
    <div class="input-group">
      <input type="number" step="1" class="form-control"
             [value]="_value" (change)="_value = $event.target.value">
        <button class="btn btn-primary" type="button" (click)="settingChange.emit(setting)" imDisabledWhileLoading>
          Speichern
        </button>
    </div>
  `,
  standalone: false,
})
export class IntegerSettingComponent implements SettingComponent<IntegerAppSetting> {
  public settingChange = new EventEmitter<FromDB<IntegerAppSetting>>();
  /** Die interne repräsentation des Wertes der Einstellung */
  _value: number;
  /** Die Einstellung */
  _setting: FromDB<IntegerAppSetting>;

  /**
   * Setzt die zu verwaltende Einstellung, und verarbeitet den Stringwert in eine
   * interne Darstellung.
   *
   * @param setting
   */
  @Input()
  set setting(setting: FromDB<IntegerAppSetting>) {
    this._setting = setting;
    const value = parseInt(setting.value, 10);

    if (Number.isNaN(value)) {
      throw new Error(`Fehler beim initialisieren von IntegerSettingComponent für Key ${this.setting.key}`);
    }

    this._value = value;
  }

  /**
   * Ruft das Setting mit seinem aktuellen Wert wieder ab.
   */
  get setting(): FromDB<IntegerAppSetting> {
    return {
      ...this._setting,
      value: String(this._value),
    };
  }
}
