import { Component } from '@angular/core';

@Component({
  selector: 'im-top-nav-container',
  templateUrl: 'top-nav-container.component.html',
  styleUrls: ['top-nav-container.component.scss'],
  standalone: false,
})
export class TopNavContainerComponent {
}
