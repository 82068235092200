import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[imOverviewContainerOverviewTemplate]',
  standalone: false,
})
export class OverviewContainerOverviewDirective {
}

@Directive({
  selector: '[imOverviewContainerCreationFormTemplate]',
  standalone: false,
})
export class OverviewContainerCreationFormDirective {
}

/**
 * Mögliche anzuzeigende Views.
 */
export enum ViewStates {
  OVERVIEW,
  CREATION_FORM,
}

/**
 * Ein Container, der eine Übersicht und eine Form zum Erstellen einer Entität anzeigt.
 */
@Component({
  selector: 'im-overview-container',
  templateUrl: 'overview-container.component.html',
  styleUrls: ['./overview-container.component.scss'],
  standalone: false,
})
export class OverviewContainerComponent {
  /**
   * Überschrift, die oben links angezeigt wird.
   */
  @Input() heading = '';
  /**
   * Text des Buttons der die Ansicht wechselt, wenn gerade die Tabelle angezeigt wird.
   */
  @Input() buttonTextCreate = 'Erstellen';
  /**
   * Text des Buttons der die Ansicht wechselt, wenn gerade das Erstellungsformular angezeigt wird.
   */
  @Input() buttonTextBackToOverview = 'Zurück zur Übersicht';

  /**
   * Das Template der Übersicht.
   */
  @ContentChild(OverviewContainerOverviewDirective, { read: TemplateRef })
  overviewTemplate: TemplateRef<OverviewContainerOverviewDirective>;

  /**
   * Das Template des Erstellungsformulars.
   */
  @ContentChild(OverviewContainerCreationFormDirective, { read: TemplateRef })
  creationFormTemplate: TemplateRef<OverviewContainerCreationFormDirective>;

  /**
   * Die aktuell angezeigt View.
   */
  activeView = ViewStates.OVERVIEW;

  /**
   * Ob gerade die Übersicht angezeigt wird.
   */
  get overviewActive() {
    return this.activeView === ViewStates.OVERVIEW;
  }

  /**
   * Ob gerade das Erstellungsformular angezeigt wird.
   */
  get creationFormActive() {
    return this.activeView === ViewStates.CREATION_FORM;
  }

  /**
   * Das Template zur aktuell anzuzeigenden View.
   */
  get activeTemplate() {
    return this.activeView === ViewStates.CREATION_FORM
      ? this.creationFormTemplate
      : this.overviewTemplate;
  }

  /**
   * Wechselt die aktive View aus.
   */
  switchView() {
    this.activeView = this.activeView === ViewStates.CREATION_FORM
      ? ViewStates.OVERVIEW
      : ViewStates.CREATION_FORM;
  }
}
