import { Component } from '@angular/core';
import { SebuKeyedTextsKey } from './keyed-texts.service';

@Component({
  selector: 'sebu-imprint',
  template: `
    <h2>Impressum</h2>

    <sebu-html-editor
      [key]="key"
    ></sebu-html-editor>
  `,
  styles: [],
  standalone: false,
})
export class ImprintComponent {
  key = SebuKeyedTextsKey.IMPRINT;
}
