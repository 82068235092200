import { Title } from '@angular/platform-browser';
import {RouterStateSnapshot, ActivatedRouteSnapshot, TitleStrategy} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomTitleStrategy implements TitleStrategy {
  constructor(private titleService: Title) {}

  updateTitle(snapshot: RouterStateSnapshot): void {
    const title = snapshot.root.data['title'];
    if (title) {
      this.titleService.setTitle(`${title} - Service-Bund`);
    } else {
      this.titleService.setTitle('Service-Bund');
    }
  }

  buildTitle(snapshot: RouterStateSnapshot): string {
    const title = this.getDeepestTitle(snapshot.root);
    return title ? `${title} - Service-Bund` : 'Service-Bund';
  }

  getResolvedTitleForRoute(snapshot: ActivatedRouteSnapshot): string {
    return this.buildTitle(snapshot as unknown as RouterStateSnapshot);
  }

  private getDeepestTitle(route: ActivatedRouteSnapshot): string | undefined {
    let deepestTitle = route.data?.['title'];
    for (const child of route.children) {
      const childTitle = this.getDeepestTitle(child);
      if (childTitle) {
        deepestTitle = childTitle;
      }
    }
    return deepestTitle;
  }
}
