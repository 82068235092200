import { Component } from '@angular/core';

@Component({
  selector: 'sebu-order-position-comments',
  template: `
    <h1>Positionskommentare</h1>

    <sebu-comment-logic
      [apiEndpoint]="apiEndpoint"
    ></sebu-comment-logic>
  `,
  styles: [],
  standalone: false,
})
export class OrderPositionCommentsComponent  {
  public apiEndpoint = 'auftrag-position-kommentar-vorschlag';

}
