import {
  AfterViewInit,
  Directive, EventEmitter,
  HostBinding,
  HostListener,
  Input, Output,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { ApiClient } from '../api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../im-modules/user/auth/auth.service';
import { IconComponent } from '../generic/icon/icon.component';
import { canEdit } from '../../im-modules/user/users/User';
import { BaseModel } from '../api/BaseModel';
import { DataTableService } from '../api/data-table/data-table.service';

@Directive({
  selector: '[imDeleteButton]',
  standalone: false,
})
export class DeleteButtonDirective implements AfterViewInit {
  @HostBinding('class.btn') btn = true;
  @HostBinding('class.btn-outline-danger') btnDanger = true;

  @Input() endpoint;
  @Input() model;
  @Input() confirmationText='Wirklich löschen?';
  @Input() successText='Erfolgreich gelöscht!';
  @Input() icon = 'trash';
  @Input() getId = (model: BaseModel) => model.Id;
  @HostListener('click') onClick = () => {
    this.remove(this.endpoint, this.model);
  }
  @Input() remove = (endpoint: string, model: BaseModel) => {
    this._remove(endpoint, model);
  }

  @Output() deleted = new EventEmitter();

  constructor(
    private api: ApiClient,
    private toastr: ToastrService,
    private auth: AuthService,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private dataTable: DataTableService,
  ) {
    const ref = this.viewContainerRef.createComponent(IconComponent);
    ref.instance.name = this.icon;
    (ref.location.nativeElement as HTMLElement).classList.add('me-1');

    // Icon einsetzen
    renderer.insertBefore(
      this.viewContainerRef.element.nativeElement,
      ref.location.nativeElement,
      this.viewContainerRef.element.nativeElement.firstChild,
    );
  }

  @Input('delete')
  _remove(endpoint: string, model: BaseModel) {

    if (confirm(this.confirmationText)) {
      this.api
        .destroy(endpoint, this.getId(model))
        .subscribe(() => {
          this.toastr.success(this.successText);
          this.dataTable.reload();
          this.deleted.emit();
        });
    }
  }

  ngAfterViewInit(): void {
    if (!canEdit(this.auth.user)) {
      const container = this.viewContainerRef.element.nativeElement as HTMLElement;
      this.renderer.removeChild(container.parentNode, container);
    }
  }
}
