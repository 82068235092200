import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ExpandedAugmentedAppointment } from '../../ical/ical-query.service';

/**
 * Fügt dem Element einen Rand hinzu, der anzeigt, von wem der Termin erstellt
 * wurde.
 */
@Directive({
  selector: '[imAppointmentCategoryBorder]',
  standalone: false,
})
export class AppointmentCategoryBorderDirective implements OnInit {
  @Input('imAppointmentCategoryBorder')
  appointment: ExpandedAugmentedAppointment;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    if (this.el.nativeElement && this.el.nativeElement instanceof HTMLElement) {
      this.el.nativeElement.style.borderLeft = `5px solid ${this.safeColor}`;
    }
  }

  private get safeColor(): string {
    return this.appointment
      && this.appointment.appointmentCategory
      && this.appointment.appointmentCategory.color
      ? this.appointment.appointmentCategory.color
      : 'transparent';
  }
}
