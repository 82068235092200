import { Component, ElementRef, Input } from '@angular/core';
import { emitLoadingError } from '../../../../common/api/loading-error-container/loading-error-container.component';
import { LaravelPaginationMetadata } from '../../../../common/api/paginator/LaravelPaginationMetadata';
import { PaginationMetadata } from '../../../../common/api/paginator/PaginationMetadata';
import { LaravelPaginatedResponse } from '../../../../common/api/LaravelPaginatedResponse';
import { HttpClient } from '@angular/common/http';
import { Message, MESSAGE_ENDPOINT } from '../../models/Message';
import { User } from '../../../user/users/User';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'im-messages-table',
  templateUrl: './messages-table.component.html',
  standalone: false,
})
export class MessagesTableComponent {
  data: Array<Message> = [];
  meta: PaginationMetadata;
  endpoint = MESSAGE_ENDPOINT;
  @Input() showDelete = false;

  constructor(
    private element: ElementRef,
    private http: HttpClient,
  ) {
    this.loadPage = this.loadPage.bind(this);
  }

  public formatEmails(user: Array<User>) {
    const emails = user
      .map(u => u.email)
      .join(', ');

    return `${user.length} (${emails})`;
  }

  loadPage(pageUrl: string) {
    this.http
      .get<LaravelPaginatedResponse<Message>>(pageUrl)
      .pipe(catchError(err => emitLoadingError(this.element, err)))
      .subscribe((response: LaravelPaginatedResponse<Message>) => {
        this.meta = new LaravelPaginationMetadata(response);
        this.data = response.data;
      });
  }
}
