import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseForm } from '../../../../common/forms/base-form';
import { displayUser, User, USER_ENDPOINT } from '../../../user/users/User';
import { ApiClient } from '../../../../common/api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import { MESSAGE_CATEGORY_ENDPOINT, MessageCategory } from '../../models/MessageCategory';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MESSAGE_ENDPOINT } from '../../models/Message';
import { DATEPICKER_DE } from '../../../../config/locales';
import { DATE_DB_FORMAT } from '../../../../config/dates';
import * as moment from 'moment';
import { DataTableService } from '../../../../common/api/data-table/data-table.service';
import { FromDB } from '../../../../common/api/FromDB';

@Component({
  selector: 'im-create-message',
  templateUrl: './create-message.component.html',
  styleUrls: ['./create-message.component.scss'],
  standalone: false,
})
export class CreateMessageComponent extends BaseForm implements OnInit {
  @Output()
  public messageCreated = new EventEmitter();

  public allCategories: Array<FromDB<MessageCategory>> = [];
  public categoriesLoading = false;
  public allUsers: Array<User> = [];
  public usersLoading = false;
  public displayUser = displayUser;

  public form = new UntypedFormGroup({
    title: new UntypedFormControl('', Validators.required),
    category: new UntypedFormControl(null, Validators.required),
    users: new UntypedFormControl(null, Validators.required),
    activationDateTime: new UntypedFormControl(null),
    endDate: new UntypedFormControl(null),
  });
  public de = DATEPICKER_DE;
  public readonly today = new Date();


  constructor(public api: ApiClient, private toastr: ToastrService, private dataTable: DataTableService) {
    super();
  }

  ngOnInit() {
    this.usersLoading = true;
    this.categoriesLoading = true;

    this.api
      .all<User>(USER_ENDPOINT)
      .subscribe(users => {
        this.usersLoading = false;
        this.allUsers = users;
      });

    this.api
      .all<MessageCategory>(MESSAGE_CATEGORY_ENDPOINT)
      .subscribe(categories => {
        this.categoriesLoading = false;
        this.allCategories = categories;
      });
  }

  public onSubmit(): void {
    super.onSubmit();

    if (this.form.valid) {
      const { users, category, ...data } = this.form.value;

      data.userIds = users.map(user => user.Id);
      data.categoryId = category;

      if (data.activationDateTime) {
        data.activationDateTime = moment(data.activationDateTime).format(DATE_DB_FORMAT);
      }

      if (data.endDate) {
        data.endDate = moment(data.endDate).format(DATE_DB_FORMAT);
      }

      this.api.store(MESSAGE_ENDPOINT, data).subscribe(
        () => {
          this.toastr.success('Die Nachricht wurde erfolgreich erstellt!');
          this.reset();
          this.dataTable.reload();
          this.messageCreated.emit();
        },
      );
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  get title () {
    return this.form.get('title');
  }

  get category () {
    return this.form.get('category');
  }

  get users () {
    return this.form.get('users');
  }

  get activationDateTime() {
    return this.form.get('activationDateTime');
  }
  get endDate() {
    return this.form.get('endDate');
  }
}
