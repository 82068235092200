import { Component, Input } from '@angular/core';
import { MessageCategory } from '../models/MessageCategory';

@Component({
  selector: 'im-message-category',
  templateUrl: 'message-category.component.html',
  styleUrls: ['message-category.component.scss'],
  standalone: false,
})
export class MessageCategoryComponent {
  @Input() category: MessageCategory;
}
