import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output, TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SettingComponentService } from './settings-components/setting-component.service';
import { AppSetting } from './models/AppSetting';
import { FromDB } from '../../../common/api/FromDB';

/**
 * Komponente zur dynamischen Instanziierung einer Anzeigekomponente für eine Einstellung.
 */
@Component({
  selector: 'im-dynamic-setting-factory',
  template: `
    <ng-template #container></ng-template>
    <div class="alert alert-danger" *ngIf="error !== null">
      <h4 class="alert-heading">
        Fehler beim Auswerten der Einstellung <span class="text-monospace">{{ setting.key }}!</span>
      </h4>
      <p class="mb-0">{{ error?.message }}</p>
    </div>
  `,
  standalone: false,
})
export class DynamicSettingFactoryComponent implements AfterViewInit {
  @Input() setting: FromDB<AppSetting>;
  @Output() settingChanged = new EventEmitter<FromDB<AppSetting>>();

  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  /** Möglicher beim rendern der Komponente entstandener Fehler. */
  error: Error | null = null;

  constructor(
    private settingComponents: SettingComponentService,
  ) { }

  ngAfterViewInit() {
    // Die Funktion muss nach ViewInit aufgerufen werden, da sonst this.container null ist
    try {
      this.renderSetting();
    } catch (error) {
      this.error = error;
    }
  }


  /**
   * Sucht sich die Settingkomponente aus den registrierten Komponenten und zeigt den Wert an.
   */
  private renderSetting() {
    const component = this.settingComponents.getComponentForType(this.setting.type);

    this.container.clear();
    const componentRef = this.container.createComponent(component);
    componentRef.instance.setting = this.setting;
    componentRef.instance.settingChange.subscribe(newSetting => this.settingChanged.emit(newSetting));
  }
}
