import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { getUserLink, User } from '../../users/User';

@Component({
  selector: 'im-user-nav-entry',
  templateUrl: 'user-nav-entry.component.html',
  styleUrls: ['user-nav-entry.component.scss'],
  standalone: false,
})
export class UserNavEntryComponent {
  constructor(public auth: AuthService) { }

  get userProfileLink() {
    return getUserLink(this.auth.user as User);
  }
}
