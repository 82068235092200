import { FromDB } from '../../../../common/api/FromDB';
import { ApiClient } from '../../../../common/api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import { identityComparator } from '../../../../common/forms/identityComparator';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BaseEntityForm } from '../../../../common/forms/BaseEntityForm';
import { BaseItem, displayCategories, ITEM_ENDPOINT } from '../../models/Item';
import { Category } from '../../models/Category';
import { isInteger } from '../../../../common/forms/validators/integerValidator';
import { DATEPICKER_DE } from '../../../../config/locales';
import * as moment from 'moment';

export interface ProductFormComponentValue {
  name: string;
  'categories': string;
  thumbnailFile?: File | null | undefined;
  publishedAt: string;
  endDate?: string | Date | null;
}

type FormProduct = BaseItem & { categories: FromDB<Category & { pivot: { sortOrder: number } }>[] };

@Component({
  selector: 'im-product-form',
  styleUrls: ['product-form.component.scss'],
  templateUrl: './product-form.component.html',
  standalone: false,
})
export class ProductFormComponent
  extends BaseEntityForm<FormProduct, ProductFormComponentValue>
  implements OnInit {
  @Input() public showSubmit = false;
  @Input() public isEditable = false;
  @Input() public entity: FormProduct = {
    thumbnailId: '',
    name: '',
    categories: [],
    creationDate: '',
    publishedAt: this.getTomorrowDate(),
    endDate: null
  };
  @Input() fileRequired = true;

  /** Ob es sich bei der Form um die Produkterstellungsform handelt */
  @Input() public isCreate = false;

  /** Flag, ob eine andere Datei hochgeladen werden soll, als eventuell schon bereits vorhanden ist. */
  public fileHasChanged = false;
  endpoint = ITEM_ENDPOINT;
  identityComparator = identityComparator;
  public displayCategories = displayCategories;
  public de = DATEPICKER_DE;
  public readonly today = new Date();

  constructor(protected toastr: ToastrService, protected api: ApiClient) {
    super(toastr, api);

    this.entity = {
      name: '',
      creationDate: '',
      publishedAt: this.getTomorrowDate(),
      endDate: null,
      // sortOrder: 0,
      thumbnailId: '',
      categories: [],
    };
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      itemName: new UntypedFormControl(this.entity.name || '', [Validators.required, Validators.minLength(1)]),
      category: new UntypedFormControl('', Validators.required),
      publishedAt: new UntypedFormControl(
        typeof this.entity.publishedAt === 'string'
          ? moment(this.entity.publishedAt, 'YYYY-MM-DD').toDate()
          : this.entity.publishedAt,
      ),
      endDate: new UntypedFormControl(
        typeof this.entity.endDate === 'string'
          ? moment(this.entity.endDate, 'YYYY-MM-DD').toDate()
          : this.entity.endDate,
      ),
      sortOrder: new UntypedFormControl(
        this.entity.categories.length > 0
          ? this.entity.categories[0].pivot.sortOrder
          : 1,
        [Validators.required, isInteger]),
      file: new UntypedFormControl('', [this.pdfValidator()]),
    });

    if (this.fileRequired) {
      (this.file as AbstractControl).setValidators([Validators.required, this.pdfValidator()]);
    }

    if (Array.isArray(this.entity.categories) && this.entity.categories.length > 0) {
      (this.category as AbstractControl).setValue(this.entity.categories[0]);
    }
  }

  onFileChange() {
    this.fileHasChanged = true;
    (this.file as AbstractControl).setValidators([Validators.required, this.pdfValidator()]);
  }

  pdfValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value || control.value.length === 0) {
        return null;
      }

      const files = control.value as FileList;
      const file = files[0];

      const mimeTypePDF = file.type === 'application/pdf';
      const endsWithPDF = file.name.endsWith('.pdf');
      const couldBePDF = mimeTypePDF || endsWithPDF;

      return !couldBePDF ? { 'pdf': 'Datei ist keine PDF-Datei!' } : null;
    };
  }

  getDataToSubmit(): ProductFormComponentValue {
    const { category, file, sortOrder, itemName, publishedAt, endDate } = this.form.value;

    const formValue: ProductFormComponentValue = {
      'categories': JSON.stringify([{
        // TODO: Hier mehrere einfügen
        'Id': (category as FromDB<Category>).Id,
        sortOrder,
      }]),
      name: itemName,
      publishedAt: moment(publishedAt).format('YYYY-MM-DD'),
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null

    };

    if (file) {
      formValue.thumbnailFile = (file as FileList).item(0);
    }

    return formValue;
  }

  getTomorrowDate(): Date {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  }

  editPublishedDateAllowed(): boolean {
    const today = new Date();
    return this.isEditable && today < new Date(this.entity.publishedAt);
  }

  @Input()
  submit(data: ProductFormComponentValue) {
  }

  get itemName() {
    return this.form.get('itemName');
  }

  get category() {
    return this.form.get('category');
  }

  get sortOrder() {
    return this.form.get('sortOrder');
  }

  get file() {
    return this.form.get('file');
  }

  public get publishedAt() {
    return this.form.get('publishedAt');
  }

  public get endDate() {
    return this.form.get('endDate');
  }
}
