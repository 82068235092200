import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ApiClient } from '../../api/ApiClient';
import { HttpErrorResponse } from '@angular/common/http';
import { canEdit } from '../../../im-modules/user/users/User';
import { AuthService } from '../../../im-modules/user/auth/auth.service';
import { FromDB } from '../../api/FromDB';

/**
 * Eine Komponente, zum Bereitstellen eines Grundgerüsts einer Detailansicht.
 * **Wichtig**: Die Methoden wie `edit`, `save`, usw. unbedingt an die Komponente
 * binden, die die Methoden übergibt! Ansonsten wird die
 * `DetailContainerComponent` als `this` verwendet und die Änderung kommt nie im
 * Parent an!
 * Aufgrund der Lifecycle Methoden, kann der Inhalt hier nicht einfach als
 * ng-content übergeben werden, siehe
 * https://medium.com/michalcafe/angulars-content-projection-trap-and-why-you-should-consider-using-template-outlet-instead-cc3c4cad87c9
 * Daher wird einfach der Inhalt in ein <ng-template>-Tag verpackt.
 * Beispiel:
 *
 * ```
 * <im-detail-container
 *   [save]="mySaveFunction"
 *   [edit]="myEditFunction"
 *   [abort]="abortEdit"
 *   [delete]="myDeleteFunction"
 *   [isEditable]="isEditable"
 *   [endpoint]="myEndpoint"
 *   #detailContainer
 * >
 *   <ng-template>
 *     <h1 class="card-title">{{testContent}}</h1>
 *   </ng-template>
 * </im-detail-container>
 * ```
 */
@Component({
  selector: 'im-detail-container',
  templateUrl: './detail-container.component.html',
  styleUrls: ['./detail-container.component.scss'],
  standalone: false,
})
export class DetailContainerComponent<T = any> implements OnInit {
  @Input() endpoint: string;
  @Input() redirectPathOn404: string;
  @Input() messageOn404: string;

  @Input() isEditable: boolean;
  @Input() showEditButtons = true;
  @Input() hideDeleteButton = false;

  @Input() edit: () => void;
  @Input() save: () => void;
  @Input() abort: () => void;
  @Input() delete: () => void;

  @Input() onEntityLoaded: (entity: any) => void;
  @ContentChild(TemplateRef) detailTemplate: TemplateRef<any>;
  hasEntity = false;
  notFound = false;

  /**
   * Flag, die *alle* Bearbeitungsfunktionen, wie Löschen oder Bearbeiten ein- oder ausblendet.
   *
   * @type {boolean}
   */
  userIsAllowedToEdit = false;

  /**
   * Flag, die nur das Löschen ein- oder ausblendet.
   *
   * @type {boolean}
   */
  public userIsAllowedToDelete = true;

  public entity: FromDB<T>;
  private id: string;

  /**
   * Transformationsfunktion, um die vom Endpunkt erhaltene Entität zu
   * bearbeiten, parsen, etc., bevor diese gespeichert wird
   */
  @Input() transformEntity: (entity: FromDB<T>) => any = x => x;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private api: ApiClient,
    auth: AuthService,
  ) {
    this.userIsAllowedToEdit = canEdit(auth.user);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.fetchEntity(this.id);
    });
  }

  fetchEntity(id: string) {
    this.api
      .show<any>(this.endpoint, id)
      .subscribe(entity => {
        this.entity = this.transformEntity(entity);
        this.hasEntity = true;
        this.notFound = false;

        if (this.onEntityLoaded) {
          this.onEntityLoaded(this.entity);
        }
      }, err => {
        this.hasEntity = false;
        this.entity = undefined as any;

        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.notFound = true;
        }
      });
  }

  refresh() {
    this.fetchEntity(this.id);
  }

  goBack() {
    this.location.back();
  }
}
