import { Component, ViewChild } from '@angular/core';
import { DetailComponentBase } from '../../../common/layout/detail-container/DetailComponentBase';
import { MESSAGE_CATEGORY_ENDPOINT, MessageCategory } from '../models/MessageCategory';
import { DetailContainerComponent } from '../../../common/layout/detail-container/detail-container.component';
import { ApiClient } from '../../../common/api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import autobind from 'autobind-decorator';
import { MessageCategoryFormComponent } from './message-category-form.component';
import { MessageCategoryService } from './message-category.service';

@Component({
  selector: 'im-message-category-details',
  template: `
    <im-detail-container
      [save]="onSaveClick"
      [edit]="editEntity"
      [delete]="safeDeleteEntity"
      [isEditable]="isEditable"
      [onEntityLoaded]="editEntityAfterRetrieved"
      [abort]="abortEdit"
      [endpoint]="endpoint"
      #detailContainer
    >
      <ng-template>
        <h1 class="card-title">
          {{ entity.name }}
        </h1>

        <im-message-category *ngIf="!isEditable" [category]="entity"></im-message-category>
        <im-message-category-form *ngIf="isEditable" [entity]="entity"
                                  [submitForm]="submitForm"></im-message-category-form>
      </ng-template>
    </im-detail-container>
  `,
  standalone: false,
})
export class MessageCategoryDetailsComponent extends DetailComponentBase<MessageCategory> {
  @ViewChild('detailContainer') detailContainer: DetailContainerComponent;
  @ViewChild(MessageCategoryFormComponent) form: MessageCategoryFormComponent;
  endpoint = MESSAGE_CATEGORY_ENDPOINT;

  constructor(
    protected api: ApiClient,
    protected toastr: ToastrService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected messageCategories: MessageCategoryService,
  ) {
    super(api, toastr, router, route);
  }

  @autobind
  onSaveClick() {
    this.form.onSubmit();
  }

  @autobind
  safeDeleteEntity() {
    if (confirm('Wirklich löschen?')) {
      this.messageCategories
        .destroy(this.entity)
        .subscribe(() => {
          this.messageCategories.onCategoryDestroyed();
        });
    }
  }

  @autobind
  submitForm(data: MessageCategory) {
    this.api
      .update(this.endpoint, this.entity.Id, data)
      .subscribe(() => {
        this.detailContainer.refresh();
        this.isEditable = false;
      });
  }
}
