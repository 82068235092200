import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { KeyedTextsService, SebuKeyedTextsKey } from './keyed-texts.service';
import { ToastrService } from 'ngx-toastr';

declare const $: any;

@Component({
  selector: 'sebu-html-editor',
  template: `
    <div class="form-group">
      <div #editor></div>
    </div>
    <div class="form-group">
      <button
        class="btn btn-primary"
        imDisabledWhileLoading
        (click)="save()"
      >
        <im-icon name="check"></im-icon>
        Speichern
      </button>
    </div>
  `,
  styles: [],
  standalone: false,
})
export class HtmlEditorComponent implements AfterViewInit {
  /**
   * @see https://summernote.org/deep-dive/#custom-toolbar-popover
   */
  @Input() toolbarConfig = [
    ['style', ['bold', 'italic', 'underline', 'clear']],
    ['font', ['strikethrough', 'superscript', 'subscript']],
    ['format', ['fontsize', 'height']],
    ['para', ['ul', 'ol', 'paragraph']],
    ['edit', ['undo', 'redo']],
  ];

  /**
   * Ein Key der aus der KeyedTexts-Tabelle.
   */
  @Input() key: SebuKeyedTextsKey;
  @Input() onSaveSuccessMessage = 'Speichern erfolgreich!';

  public set content(content: string) {
    this.$editor.summernote('code', content);
  }

  public get content(): string {
    return this.$editor.summernote('code');
  }

  @ViewChild('editor') editor: ElementRef<HTMLDivElement>;

  constructor(
    private readonly keyedTexts: KeyedTextsService,
    private readonly toastr: ToastrService,
  ) { }

  ngAfterViewInit() {
    this.$editor.summernote({
      lang: 'de-DE',
      toolbar: this.toolbarConfig,
    });

    this.keyedTexts
      .get(this.key)
      .subscribe(content => this.content = content.text);
  }

  public save() {
    this.keyedTexts
      .save(this.key, this.content)
      .subscribe(updatedRemoteContent => {
        this.toastr.success(
          this.onSaveSuccessMessage,
        );
        this.content = updatedRemoteContent.text;
      });
  }

  public get $editor() {
    return $(this.editor.nativeElement);
  }
}
