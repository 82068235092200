import { Component, ViewChild } from '@angular/core';
import { ApiClient } from '../../../common/api/ApiClient';
import autobind from 'autobind-decorator';
import { CONTACT_POSITIONS_ENDPOINT, ContactPosition } from '../models/ContactPosition';
import { OverviewContainerComponent } from '../../../common/layout/overview-container/overview-container.component';
import { CustomerContactPositionFormComponent } from './form/customer-contact-position-form.component';

@Component({
  selector: 'sebu-customer-contact-positions',
  template: `
    <im-overview-container heading="Kontaktpositionen" buttonTextCreate="Neue Kontaktposition erstellen">
      <sebu-customer-contact-position-table *imOverviewContainerOverviewTemplate></sebu-customer-contact-position-table>
      <sebu-customer-contact-position-form
        *imOverviewContainerCreationFormTemplate
        [showSubmit]="true" [send]="send"
      ></sebu-customer-contact-position-form>
    </im-overview-container>
  `,
  styles: [],
  standalone: false,
})
export class CustomerContactPositionsComponent {
  @ViewChild(CustomerContactPositionFormComponent)
  form: CustomerContactPositionFormComponent;

  @ViewChild(OverviewContainerComponent)
  container: OverviewContainerComponent;

  constructor(private api: ApiClient) {
  }

  @autobind
  send(data: ContactPosition) {
    this.api
      .store(CONTACT_POSITIONS_ENDPOINT, data)
      .subscribe(() => {
        this.form.onSuccessfulSubmit('Position erfolgreich erstellt!');
        this.container.switchView();
      });
  }
}
