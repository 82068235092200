import { Component, Input } from '@angular/core';

export enum IconSize {
  default = '',
  large = 'lg',
  double = '2x',
  triple = '3x',
  sevenTimes = '7x',
  tenTimes = '10x',
}

/**
 * @see https://getbootstrap.com/docs/4.0/extend/icons/
 * @see https://useiconic.com/open/
 */
@Component({
  selector: 'im-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: false,
})
export class IconComponent {
  @Input() name: String;
  @Input() size = IconSize.default;
  @Input() additionalClasses = '';

  get sizeClass() {
    return this.size !== IconSize.default
      ? 'fa-' + this.size
      : '';
  }
}
