import { Component, ViewChild } from '@angular/core';
import { MESSAGE_CATEGORY_ENDPOINT, MessageCategory } from '../models/MessageCategory';
import { ApiClient } from '../../../common/api/ApiClient';
import { OverviewContainerComponent } from '../../../common/layout/overview-container/overview-container.component';
import { MessageCategoryFormComponent } from './message-category-form.component';
import autobind from 'autobind-decorator';

@Component({
  selector: 'im-message-category-overview',
  template: `
    <im-overview-container heading="Mitteilungskategorien" buttonTextCreate="Neue Kategorie anlegen">
      <div *imOverviewContainerOverviewTemplate>
        <im-message-category-table></im-message-category-table>
      </div>

      <div *imOverviewContainerCreationFormTemplate>
        <im-message-category-form [isCreationForm]="true" [submitForm]="submit"></im-message-category-form>
      </div>
    </im-overview-container>
  `,
  standalone: false,
})
export class MessageCategoryOverviewComponent {
  @ViewChild(OverviewContainerComponent) overviewContainer: OverviewContainerComponent;
  @ViewChild(MessageCategoryFormComponent) form: MessageCategoryFormComponent;

  constructor(private api: ApiClient) {
  }

  @autobind
  submit(data: MessageCategory) {
    this.api
      .store(MESSAGE_CATEGORY_ENDPOINT, data)
      .subscribe(() => {
        if (this.overviewContainer) {
          this.overviewContainer.switchView();
        }
        this.form.onSuccessfulSubmit('Kategorie wurde erfolgreich erstellt!');
      });
  }
}
