import { Component, Input } from '@angular/core';
import { ProductIsNewService } from './product-is-new.service';
import { Item } from '../../models/Item';
import { FromDB } from '../../../../common/api/FromDB';

/**
 * Komponente, die anzeigt, ob das übergebene Produkt neu ist oder nicht.
 * Dabei wird das entsprechende AppSetting überprüft.
 */
@Component({
  selector: 'im-new-product-badge',
  templateUrl: 'new-product-badge.component.html',
  styleUrls: ['new-product-badge.component.scss'],
  standalone: false,
})
export class NewProductBadgeComponent {
  @Input() product: FromDB<Item>;

  constructor(
    public isNew: ProductIsNewService,
  ) { }
}
