import {Component, ElementRef, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Checkbox} from 'primeng/checkbox';

@Component({
  selector: 'im-switch',
  template: `
    <label class="switch" [class.readonly]="readOnly">
      <input type="checkbox" [id]="inputId" [disabled]="readOnly"
             (change)="onChange($event.target.checked)" (blur)="onTouched()" #checkboxRef>
      <span class="slider"></span>
    </label>
  `,
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class SwitchComponent implements ControlValueAccessor {
  @Input() inputId = '';
  @Input() readOnly = false;
  @ViewChild('checkboxRef') private checkboxRef: ElementRef<Checkbox>;

  public onChange = (_) => {
  }

  public onTouched = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.checkboxRef) {
      this.checkboxRef.nativeElement.disabled = isDisabled;
    }
  }

  writeValue(obj: any): void {
    if (this.checkboxRef) {
      (this.checkboxRef.nativeElement as any).checked = obj;
    }
    this.onChange(obj);
  }
}
