import { Component, OnInit } from '@angular/core';
import { AdditionalInformationService } from './additional-information.service';

@Component({
  selector: 'sebu-additional-information',
  template: `
    <im-overview-container
      #overview
      heading="Zusatzinformationen"
      buttonTextCreate="Neue Zusatzinformation erstellen"
    >
      <im-loading-error-container *imOverviewContainerOverviewTemplate>
        <im-data-table [endpoint]="zusatzInfos.ENDPOINT">
          <ng-template imDataTableHeaders>
            <th>Artikelnummer</th>
            <th>Information</th>
            <th>Art</th>
            <th class="actions-column">Aktionen</th>
          </ng-template>

          <ng-template imDataTableRow let-row>
            <td>{{row?.artikel?.Artikelnummer}}</td>
            <td>{{row?.name}}</td>
            <td>{{displayType(row?.blob?.mimetype)}}</td>
            <td>
              <div class="btn-group">
<!--                <button imDetailButton [model]="row">Details</button>-->
                <button imDeleteButton [model]="row" [endpoint]="zusatzInfos.ENDPOINT">Löschen</button>
              </div>
            </td>
          </ng-template>
        </im-data-table>
      </im-loading-error-container>

      <ng-container *imAdminOnly>
        <sebu-create-additional-information
          *imOverviewContainerCreationFormTemplate
          (created)="overview.switchView()"
        ></sebu-create-additional-information>
      </ng-container>
    </im-overview-container>
  `,
  standalone: false,
})
export class AdditionalInformationComponent {
  constructor(public zusatzInfos: AdditionalInformationService) { }

  displayType(type: string) {
    return {
      'application/pdf': 'PDF',
      'video/mp4': 'Video',
    }[type] || 'Unbekannt';
  }
}
