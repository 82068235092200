import { Component, ViewChild } from '@angular/core';
import { DetailComponentBase } from '../../../common/layout/detail-container/DetailComponentBase';
import { ApiClient } from '../../../common/api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { WAREN_GR_ENDPOINT, WarenGrWithIcon } from '../models/WarenGr';
import { DetailContainerComponent } from '../../../common/layout/detail-container/detail-container.component';
import autobind from 'autobind-decorator';
import { SortimentFormComponent, SortimentFormValue } from './sortiment-form.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'sebu-sortiment-detail',
  template: `
    <im-detail-container
      [save]="onSaveClick"
      [edit]="editEntity"
      [isEditable]="isEditable"
      [hideDeleteButton]="true"
      [onEntityLoaded]="editEntityAfterRetrieved"
      [abort]="abortEdit"
      [endpoint]="endpoint"
    >
      <ng-template>
        <h1 class="card-title">{{ entity.Bezeichnung }}</h1>

        <sebu-sortiment-form
          [entity]="entity"
          [onSubmit]="submit"
          [isEditable]="isEditable"
          (iconDeleted)="onIconDeleted()"
        ></sebu-sortiment-form>
      </ng-template>
    </im-detail-container>
  `,
  standalone: false,
})
export class SortimentDetailComponent extends DetailComponentBase<WarenGrWithIcon> {
  @ViewChild(DetailContainerComponent)
  detailContainer: DetailContainerComponent;

  @ViewChild(SortimentFormComponent)
  form: SortimentFormComponent;

  endpoint = WAREN_GR_ENDPOINT;

  constructor(
    protected api: ApiClient,
    protected toastr: ToastrService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected http: HttpClient,
  ) {
    super(api, toastr, router, route);
  }

  @autobind
  onSaveClick() {
    this.form.onSubmit();
  }

  @autobind
  submit(value: SortimentFormValue) {
    const formData = new FormData();

    formData.append('icon', value.icon[0]);
    formData.append('_method', 'PUT');

    this.http
      .post(
        ApiClient.normalizeEndpoint(WAREN_GR_ENDPOINT) + '/' + this.entity.Id,
        formData,
      )
      .subscribe(response => {
        this.form.reset();
        this.toastr.success('Icon erfolgreich aktualisiert!');
        this.detailContainer.refresh();
      }, error => {
        this.toastr.error("Das "+error.error.message)
      });
  }

  onIconDeleted() {
    this.toastr.success('Logo erfolgreich gelöscht!');
    this.form.reset();
    this.isEditable = false;
    this.detailContainer.refresh();
  }
}
