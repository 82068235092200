import {
  AfterContentInit,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  OnDestroy,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckableOptionComponent } from './checkable-option.component';
import { Subscription } from 'rxjs';

/**
 * Eine Komponente zum Anzeigen einer Multiple-Choice-Liste. Die Options werden, ähnlich wie bei
 * einem <select>-Element als direkte Children in Form von <checkable-option>-Elementen übergeben.
 * Hier sollte das auszuwählende Model an das [model]-Attribut des <checkable-option>-Elements
 * gebunden werden.
 *
 * **Wichtig**: Falls das Model etwas komplexer aufgebaut ist oder deserialisiert wird, sollte die
 * `comparator`-Funktion übergeben werden. Diese überprüft, ob zwei Models gleich sind. So kann die
 * MultipleChoiceList im Fall, dass bereits ausgewählte Models an die Liste gebunden sind erkennen,
 * welche der bereits ausgewählten Models zu welchen Optionen gehören.
 */
@Component({
  selector: 'im-multiple-choice-list',
  templateUrl: './multiple-choice-list.component.html',
  styleUrls: ['./multiple-choice-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleChoiceListComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class MultipleChoiceListComponent implements ControlValueAccessor, AfterContentInit, OnDestroy {
  @Input() public disabled = false;
  /**
   * Eine Funktion, die zwei Objekte miteinander vergleicht, und schaut, ob sie identisch sind.
   * Dies wird benötigt, um im Model der Liste gebundene Objekte mit denen der Options aus den
   * <checkable-options>-Kindelementen zu vergleichen.
   */
  @Input() public comparator: (optionModel: any, writtenItem: any) => boolean = this.defaultComparator;

  @ContentChildren(CheckableOptionComponent)
  private checkableOptionChildren: QueryList<CheckableOptionComponent>;
  private writtenValue: any;
  private _checkableOptions: Array<CheckableOptionComponent> = [];
  private subs = new Subscription();

  public ngAfterContentInit() {
    this.updateChildren();
    this.subs.add(this.checkableOptionChildren.changes.subscribe(() => {
      this.updateChildren();
    }));
  }

  private updateChildren() {
    // Synchron children updaten, um die korrekten Referenzen zu erhalten
    this._checkableOptions = this.checkableOptionChildren.toArray();
    this._checkableOptions.forEach(option => {
      option.registerOnChange(() => {
        this.onChange(this.value);
      });
    });

    // Asynchron models updaten, da sonsst Angulars Change-Detection-Cycle
    // durcheinanderkommt und die View nicht mit dem Model übereinstimmt
    setTimeout(() => {
      this.updateOptionsToCheck(this.writtenValue);
    });
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public toggleAll(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    const newValue = !this.allChecked;

    this.checkableOptions.forEach(option => {
      option.checked = newValue;
    });

    this.onChange(this.value);
  }

  private defaultComparator(optionModel: any, writtenItem: any): boolean {
    return optionModel.Id === writtenItem.Id;
  }

  private updateOptionsToCheck(obj: any) {
    if (Array.isArray(obj)) {
      (obj as Array<any>).forEach(item => {
        this.checkableOptions.forEach(option => {
          if (this.comparator(option.model, item)) {
            option.checked = true;
            this.onChange(this.value);
          }
        });
      });
    }

    if (obj === null) {
      // Zurücksetzen
      this.checkableOptions.forEach(option => {
        option.checked = false;
        this.onChange(this.value);
      });
    }
  }

  writeValue(obj: any): void {
    this.writtenValue = obj;
    // Children aktualisieren, da eventuell neue Checks benötigt werden
    this.updateOptionsToCheck(obj);

    this.onChange(this.value);
  }

  public get checkableOptions() {
    return this._checkableOptions;
  }

  public get allChecked() {
    return this.value.length === this.checkableOptions.length
      && this.value.length !== 0;
  }

  public get value() {
    return this.checkableOptions
      .filter(option => option.checked)
      .map(option => option.model);
  }

  public onChange = (newValues: Array<any>) => {};
  public onTouched = (newValues: Array<any>) => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
