import { Component } from '@angular/core';
import { WAREN_GR_ENDPOINT } from '../models/WarenGr';

@Component({
  selector: 'sebu-sortimente',
  template: `
    <h1 class="page-title">Warengruppen</h1>

    <im-data-table [endpoint]="endpoint">
      <ng-template imDataTableHeaders>
        <th>Bezeichnung</th>
        <th width="200px">Aktionen</th>
      </ng-template>

      <ng-template imDataTableRow let-row>
        <td>{{ row.Bezeichnung }}</td>
        <td>
          <div class="btn-group">
            <button imDetailButton [model]="row">Details</button>
          </div>
        </td>
      </ng-template>
    </im-data-table>
  `,
  styles: [],
  standalone: false,
})
export class SortimenteComponent {
  endpoint = WAREN_GR_ENDPOINT;

  constructor() {
  }
}
