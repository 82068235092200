import { Component } from '@angular/core';
import { MESSAGE_CATEGORY_ENDPOINT, MessageCategory } from '../models/MessageCategory';
import { FromDB } from '../../../common/api/FromDB';
import { MessageCategoryService } from './message-category.service';
import autobind from 'autobind-decorator';
import { DataTableService } from '../../../common/api/data-table/data-table.service';

@Component({
  selector: 'im-message-category-table',
  templateUrl: 'message-category-table.component.html',
  styleUrls: ['message-category-table.component.scss'],
  standalone: false,
})
export class MessageCategoryTableComponent {
  endpoint = MESSAGE_CATEGORY_ENDPOINT;

  constructor(
    protected messageCategories: MessageCategoryService,
    protected dataTable: DataTableService,
  ) {
  }

  @autobind
  deleteCategory(_: string, category: FromDB<MessageCategory>) {
    if (confirm('Wirklich löschen?')) {
      this.messageCategories
        .destroy(category)
        .subscribe(() => {
          this.messageCategories.onCategoryDestroyed(false);
          this.dataTable.reload();
        });
    }
  }
}
