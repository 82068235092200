<form [formGroup]="form" (submit)="onSubmit()">
  <!---------------------------------------------------------------------------
                                   Name
  ---------------------------------------------------------------------------->
  <im-form-group-container
    label="Name" id="itemName"
    errorMessage="Bitte geben Sie einen Namen an!"
    [editable]="isEditable" [isValid]="isFieldInValid('itemName')"
    *ngIf="isEditable"
  >
    <ng-template imFormGroupEditable let-id>
      <input type="text" class="form-control" [id]="id"
             [formControl]="itemName"
             [ngClass]="displayFieldCss('itemName')"/>
    </ng-template>

    <ng-template imFormGroupReadable let-id>
      <span class="form-control-plaintext" [id]="id">{{entity.name}}</span>
    </ng-template>
  </im-form-group-container>

  <!---------------------------------------------------------------------------
                              Veröffentlichungsdatum
  ---------------------------------------------------------------------------->

  <div class="form-group">
    <label>Veröffentlichen am</label>
    <ng-container *ngIf="editPublishedDateAllowed()">
      <p-date-picker
        class="ui-fluid"
        dateFormat="dd.mm.yy"
        [minDate]="today"
        [lang]="de"
        [showTime]="false"
        [formControl]="publishedAt"
      >
      </p-date-picker>
    </ng-container>
    <ng-container *ngIf="!editPublishedDateAllowed()">
      <span class="form-control-plaintext">{{entity.publishedAt | sbDate:true}}</span>
    </ng-container>
  </div>

  <!---------------------------------------------------------------------------
                                     Enddatum
 ---------------------------------------------------------------------------->

  <div class="form-group">
    <label>Löschen am <span class="text-muted">(optional)</span></label>
    <ng-container *ngIf="isEditable">
      <p-date-picker
        class="ui-fluid"
        dateFormat="dd.mm.yy"
        [minDate]="today"
        [lang]="de"
        [showTime]="false"
        [formControl]="endDate"
      >
      </p-date-picker>
    </ng-container>
    <ng-container *ngIf="!isEditable">
      <span class="form-control-plaintext">{{entity.endDate | sbDate:true}}</span>
    </ng-container>
  </div>


  <!---------------------------------------------------------------------------
                                   Kategorie
  ---------------------------------------------------------------------------->
  <im-form-group-container
    label="Kategorie" id="category"
    errorMessage="Bitte geben Sie eine Kategorie an!"
    [editable]="isEditable" [isValid]="isFieldInValid('category')"
  >
    <ng-template imFormGroupEditable let-id>
      <im-category-selector [formControl]="category"></im-category-selector>
    </ng-template>

    <ng-template imFormGroupReadable let-id>
      <span class="form-control-plaintext" [id]="id">{{displayCategories(entity)}}</span>
    </ng-template>
  </im-form-group-container>

  <!---------------------------------------------------------------------------
                                  Sortierreihenfolge
  ---------------------------------------------------------------------------->
  <im-form-group-container
    label="Stelle in der Sortierreihenfolge" id="sortOrder"
    errorMessage="Bitte geben sie eine gültige Stelle in der Sortierreihenfolge an!"
    [editable]="isEditable" [isValid]="isFieldInValid('sortOrder')"
  >
    <ng-template imFormGroupEditable let-id>
      <input type="number" min="0" [id]="id" class="form-control"
             [formControl]="sortOrder" step="1" min="1"
             [ngClass]="displayFieldCss('sortOrder')"/>
    </ng-template>

    <ng-template imFormGroupReadable let-id>
      <span class="form-control-plaintext" [id]="id">{{entity?.categories[0]?.pivot.sortOrder}}</span>
    </ng-template>
  </im-form-group-container>

  <!---------------------------------------------------------------------------
                                  Datei mit PDF
  ---------------------------------------------------------------------------->
  <div class="form-group">
    <label for="file">PDF</label>
    <blob-image *ngIf="!fileHasChanged && !isCreate" [blob]="entity?.thumbnailId">
    </blob-image>
    <im-file-input id="file" [formControl]="file" accept="application/pdf"
                    placeholder="PDF hochladen..." (change)="onFileChange()"
                    [inputClasses]="displayFieldCss('file')" *ngIf="isEditable">
    </im-file-input>
    <im-field-error-display
      [displayError]="isFieldInValid('file')"
      errorMsg="Bitte laden Sie eine PDF-Datei bis zu 50MB hoch!">
    </im-field-error-display>
  </div>


  <!---------------------------------------------------------------------------
  -                                                                           -
  -                                 Submit                                    -
  -                                                                           -
  ---------------------------------------------------------------------------->
  <div class="form-group" *ngIf="showSubmit">
    <button class="btn btn-primary" type="submit" imDisabledWhileLoading>
      <im-icon name="plus"></im-icon>
      Erstellen
    </button>
  </div>
</form>
