import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { ProductFormComponent, ProductFormComponentValue } from '../product-form/product-form.component';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import autobind from 'autobind-decorator';
import { ITEM_ENDPOINT } from '../../models/Item';
import { ApiClient } from '../../../../common/api/ApiClient';

@Component({
  selector: 'im-create-product',
  templateUrl: './create-product.component.html',
  standalone: false,
})
export class CreateProductComponent {
  @Output()
  public productCreated = new EventEmitter();

  @ViewChild('productForm') productForm: ProductFormComponent;

  constructor(private http: HttpClient, private toastr: ToastrService) {
  }

  @autobind
  submit(data: ProductFormComponentValue) {
    const formData = new FormData();

    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }

    this.http
      .post(ApiClient.normalizeEndpoint(ITEM_ENDPOINT), formData)
      .subscribe(() => {
        this.toastr.success('Produkt erfolgreich erstellt!');
        this.productCreated.emit();
      });
  }
}
