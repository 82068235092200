import { Component } from '@angular/core';
import { GlobalLoadingIndicatorService } from './global-loading-indicator.service';

@Component({
  selector: 'im-global-loading-indicator',
  template: `
    <div class="progress global-loading loading" [class.loading]="loadingService.loading$ | async">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%" aria-valuenow="100"
           aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  `,
  styleUrls: ['./global-loading-indicator.component.scss'],
  standalone: false,
})
export class GlobalLoadingIndicatorComponent {
  constructor(public loadingService: GlobalLoadingIndicatorService) {
  }
}
