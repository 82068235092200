import { SettingComponent } from './setting.component';
import { Component, EventEmitter, Input } from '@angular/core';
import { AppSetting } from '../models/AppSetting';
import { FromDB } from '../../../../common/api/FromDB';

export interface BooleanAppSetting extends AppSetting {
  type: 'bool';
  value: 'true' | 'false';
}

/**
 * Komponente zum verwalten eines Boolean-Wertes.
 */
@Component({
  selector: 'im-bool-setting',
  template: `
    <input type="checkbox" [checked]="_value" (change)="onChange($event)"/>
  `,
  standalone: false,
})
export class BoolSettingComponent implements SettingComponent<BooleanAppSetting> {
  public settingChange = new EventEmitter<FromDB<BooleanAppSetting>>();
  /** Die interne repräsentation des Wertes der Einstellung */
  _value = false;
  /** Die Einstellung */
  _setting: FromDB<BooleanAppSetting>;

  onChange(event: Event) {
    this._value = (event.target as HTMLInputElement).checked;
    this.settingChange.emit(this.setting);
  }

  /**
   * Setzt die zu verwaltende Einstellung, und verarbeitet den Stringwert in eine
   * interne Darstellung.
   *
   * @param setting
   */
  @Input()
  set setting(setting: FromDB<BooleanAppSetting>) {
    this._setting = setting;

    if (setting.value === 'true') {
      this._value = true;
    } else if (setting.value === 'false') {
      this._value = false;
    } else {
      throw new Error(`Fehler beim initialisieren von BoolSettingComponent für Key ${this.setting.key}`);
    }
  }

  /**
   * Ruft das Setting mit seinem aktuellen Wert wieder ab.
   */
  get setting(): FromDB<BooleanAppSetting> {
    return {
      ...this._setting,
      value: this.value,
    };
  }

  /**
   * Ruft den Wert der Komponente wieder deserialisiert ab.
   */
  get value() {
    return this._value ? 'true' : 'false';
  }
}
