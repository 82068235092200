import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './sebu.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './sebu/home/home.component';
import * as moment from 'moment';
import { APP_LOCALE } from './config/locales';
import { CommonModule } from './common/common.module';
import { ServiceBundRoutingModule } from './sebu.routing';
import { AuthModule } from './im-modules/user/auth/auth.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SB_IM_MODULE_CONFIGURATION_PROVIDERS } from './sebu-im-components.config';
import { GlobalLoadingIndicatorInterceptor } from './common/api/global-loading-indicator/global-loading-indicator.interceptor';
import { LogoComponent } from './sebu/logo.component';
import { AjaxErrorToastrInterceptor } from './common/api/ajax-error-toastr.interceptor';
import { TopNavComponent } from './sebu/top-nav.component';
import {CustomTitleStrategy} from './title-strategy';
import {TitleStrategy} from '@angular/router';
import {CalendarModule} from "primeng/calendar";
import {providePrimeNG} from "primeng/config";
import Aura from '@primeng/themes/aura';
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";


@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        LogoComponent,
        TopNavComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        CalendarModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
        }),
        NgbModule,
        // Selbst erstellte Module
        CommonModule.forRoot(),
        ServiceBundRoutingModule,
        // IM-Module (Viele werden lazy-loaded, diese sind dann in der Routendefinitionsdatei angegeben)
        AuthModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalLoadingIndicatorInterceptor,
            multi: true,
        },
        {
            provide: TitleStrategy,
            useClass: CustomTitleStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AjaxErrorToastrInterceptor,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: APP_LOCALE },
        // Konfigurationen der IM-Module
        ...SB_IM_MODULE_CONFIGURATION_PROVIDERS,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimationsAsync(),
        providePrimeNG({
          theme: {
            preset: Aura,
            options: {
              darkModeSelector: false || 'none',

            }
          },
          translation: {
            today: 'Heute',
            clear: 'Löschen'
          }
        })
    ] })
export class AppModule {
  constructor() {
    moment.locale('de');
  }
}
