import { Component, OnDestroy, OnInit } from '@angular/core';
import { displayUser, User, USER_ENDPOINT } from '../../../../../im-modules/user/users/User';
import { DATEPICKER_DE } from '../../../../../config/locales';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { DATE_QUERY_PARAM_FORMAT } from '../../../../../config/dates';
import { ApiClient } from '../../../../../common/api/ApiClient';
import { CustomerDetailService } from '../../customer-detail.service';

@Component({
  selector: 'sebu-customer-appointments',
  templateUrl: 'customer-appointments.component.html',
  styles: [],
  standalone: false,
})
export class CustomerAppointmentsComponent implements OnInit, OnDestroy {
  public selectedDates: Date[] = [];
  public allUsers: Array<User> = [];
  public de = DATEPICKER_DE;
  public selectedUser: User | null;
  private querySub: Subscription;
  public displayUser = displayUser;
  showUserDropDown = false;

  constructor(
    private api: ApiClient,
    protected route: ActivatedRoute,
    private router: Router,
    protected customerDetailService: CustomerDetailService,
  ) {
    this.querySub = this.route.queryParams.subscribe(this.onQuery.bind(this));
  }

  ngOnInit() {
    this.api.all<User>(USER_ENDPOINT).subscribe(users => {
      this.allUsers = users;

      if (this.allUsers.length === 1) {
        // Es existiert nur ein Nutzer, nur diesen anzeigen
        this.selectedUser = this.allUsers[0];
      } else {
        this.showUserDropDown = true;
        this.inferUserByCustomer();
      }

      this.querySub = this.route.queryParams.subscribe(this.onQuery.bind(this));
    });
  }

  private inferUserByCustomer() {
    if (this.customer) {
      const innendienstler = this.customer.Innendienstler;
      const aussendienstler = this.customer.Aussendienstler;

      const inferredUserId =
        (innendienstler && innendienstler.user && innendienstler.user.Id)
        || (aussendienstler && aussendienstler.user && aussendienstler.user.Id)
        || null;

      if (inferredUserId !== null) {
        this.router.navigate([], {
          relativeTo: this.route,
          replaceUrl: true,
          queryParamsHandling: 'merge',
          queryParams: {
            user: inferredUserId,
          },
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.querySub) {
      this.querySub.unsubscribe();
    }
  }

  onQuery(params: Params) {
    const requestedUserId = params.user;
    const from = moment(params.from, DATE_QUERY_PARAM_FORMAT);
    const to = moment(params.to, DATE_QUERY_PARAM_FORMAT);
    this.selectedDates = [];

    if (!from.isValid() && !to.isValid()) {
      this.selectedDates = [
        new Date(),
        moment().add(2, 'weeks').toDate(),
      ];
    }
    if (from.isValid()) {
      this.selectedDates.push(from.toDate());
    }
    if (to.isValid()) {
      this.selectedDates.push(to.toDate());
    }

    if (requestedUserId) {
      const selectedUser = this.allUsers
        .find(user => requestedUserId === user.Id);

      if (selectedUser) {
        this.selectedUser = selectedUser;
      } else {
        this.selectedUser = null;
      }
    }
  }

  onUserChanged(user: User | null) {
    if (user && user.Id) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          user: user.Id,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  onSelectedDayChange() {
    const [from, to] = this.selectedDates;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        from: from !== null ? moment(from).format(DATE_QUERY_PARAM_FORMAT) : null,
        to: to !== null ? moment(to).format(DATE_QUERY_PARAM_FORMAT) : null,
      },
      queryParamsHandling: 'merge',
    });
  }

  get canDisplayOverview() {
    return this.selectedUser
      && this.allUsers.length > 0
      && this.selectedDates.length === 2;
  }

  get from() {
    return this.selectedDates.length > 0
      ? this.selectedDates[0]
      : null;
  }

  get to() {
    return this.selectedDates.length > 1
      ? this.selectedDates[1]
      : null;
  }

  public get customer() {
    return this.customerDetailService.customer;
  }
}
