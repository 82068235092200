import { Component } from '@angular/core';

@Component({
  selector: 'sebu-orders',
  template: `
    <h1 class="page-title">Aufträge</h1>

    <sebu-orders-table></sebu-orders-table>
  `,
  standalone: false,
})
export class OrdersComponent {
}
