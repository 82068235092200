import { Component, ViewChild } from '@angular/core';
import { OverviewContainerComponent } from '../../../common/layout/overview-container/overview-container.component';

@Component({
  selector: 'im-messages',
  template: `
    <im-overview-container heading="Mitteilungen" buttonTextCreate="Neue Mitteilung erstellen">
      <im-loading-error-container *imOverviewContainerOverviewTemplate>
        <im-messages-table></im-messages-table>
      </im-loading-error-container>

      <ng-container *imAdminOnly>
        <im-create-message
          *imOverviewContainerCreationFormTemplate
          (messageCreated)="onMessageCreated()"
        ></im-create-message>
      </ng-container>
    </im-overview-container>
  `,
  standalone: false,
})
export class MessagesComponent {
  @ViewChild(OverviewContainerComponent)
  overviewContainer: OverviewContainerComponent;

  onMessageCreated() {
    this.overviewContainer.switchView();
  }
}
