import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, Credentials } from '../auth.service';

@Component({
  selector: 'im-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: false,
})
export class LoginComponent implements OnInit {
  user: Credentials = { email: '', password: '' };

  constructor(public auth: AuthService, public router: Router) {
  }

  onSubmit() {
    this.auth.login(this.user).subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  ngOnInit() {
    if (this.auth.isAuthenticated) {
      this.router.navigate(['/']);
    }
  }
}
