import { Component } from '@angular/core';
import * as Paths from '../sebu.paths';
import { PATHS as ProductPaths } from '../im-modules/presentation/presentation.routing';
import { PATHS as MessagesPaths } from '../im-modules/messages/messages.routing';
import { PATHS as CalendarPaths } from '../im-modules/calendar/calendar.paths';
import { PATHS as CustomerPaths } from './customers/customers.routing';
import { PATHS as SettingsPaths } from './settings/settings.routing';
import { PATHS as ArticlePaths } from './articles/articles.routing';
import { PATHS as OrderPaths } from './orders/orders.routing';
import { hasRole, User } from '../im-modules/user/users/User';
import { Roles } from '../im-modules/user/users/Role';
import {
  NavRoute,
  TopNavComponentConfig,
} from '../common/layout/top-nav/top-nav-routes.component';
import { AuthService } from '../im-modules/user/auth/auth.service';

@Component({
  selector: 'sebu-top-nav',
  template: `
    <im-top-nav-container *ngIf="auth.isAuthenticated">
      <a class="navbar-brand" routerLink="/">
        <sebu-logo></sebu-logo>
      </a>

      <im-top-nav-collapse>
        <im-top-nav-routes [config]="config"></im-top-nav-routes>

        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <im-user-nav-entry></im-user-nav-entry>
          </li>
        </ul>
      </im-top-nav-collapse>
    </im-top-nav-container>
  `,
  styles: [],
  standalone: false,
})
export class TopNavComponent {
  constructor(public auth: AuthService) {
  }

  get isSuperAdmin() {
    const user = this.auth.user;
    return user && hasRole(user, Roles.SUPER_ADMIN);
  }

  get isAdmin() {
    const user = this.auth.user;
    return user && (hasRole(user, Roles.SUPER_ADMIN) || hasRole(user, Roles.BACKEND_ADMIN));
  }

  get config(): TopNavComponentConfig {
    return [
      this.users,
      this.calendar,
      this.messages,
      this.presentation,
      this.customers,
      this.articles,
      this.orders,
      this.settings,
    ]
    // Null-Werte filtern. Diese werden zurückgegeben, falls ein nutzer keine Berechtigung besitzt, bestimmte
    // Routen zu sehen.
      .filter((route): route is NavRoute => route !== null);
  }

  get users(): NavRoute {
    return {
      label: 'Nutzer',
      path: Paths.USERS,
      canAccess: (user: User) => hasRole(user, Roles.SUPER_ADMIN) || hasRole(user, Roles.BACKEND_ADMIN) ,
    };
  }

  get calendar(): NavRoute {
    const routes: NavRoute = {
      label: 'Kalender',
      path: Paths.CALENDAR,
    };

    if (this.isAdmin) {
      routes.children = [
        {
          label: 'Termine',
          path: CalendarPaths.appointments,
          routerLinkActiveOptions: {
            exact: true,
          },
        },
        {
          label: 'Kategorien',
          path: CalendarPaths.appointmentTypes,
        },
        {
          label: 'Befreiungen',
          path: CalendarPaths.reportingExemptions,
        },
      ];
    }

    return routes;
  }

  get messages(): NavRoute {
    const routes: NavRoute = {
      label: 'Mitteilungen',
      path: Paths.MESSAGES,
    };

    if (this.isAdmin) {
      routes.children = [
        {
          label: 'Übersicht',
          path: MessagesPaths.messages,
          routerLinkActiveOptions: {
            exact: true,
          },
        },
        {
          label: 'Kategorien',
          path: MessagesPaths.categories,
        },
      ];
    }

    return routes;
  }

  get presentation(): NavRoute {
    return {
      label: 'Präsentation',
      path: Paths.PRESENTATION,
      children: [
        {
          label: 'Produkte',
          path: ProductPaths.products,
          routerLinkActiveOptions: {
            exact: true,
          },
        },
        {
          label: 'Kategorien',
          path: ProductPaths.categories,
        },
      ],
    };
  }

  get customers(): NavRoute {
    const routes: NavRoute = {
      label: 'Kunden',
      path: Paths.CUSTOMERS,
      children: [
        {
          label: 'Übersicht',
          path: CustomerPaths.customers,
          routerLinkActiveOptions: {
            exact: true,
          },
        },
        {
          label: 'Neukunden',
          path: CustomerPaths['new-customers'],
        },
      ],
    };

    if (this.isAdmin) {
      routes.children = (routes.children || []).concat(
        {
          label: 'Kontaktpositionen',
          path: CustomerPaths['contact-positions'],
        },
      );
    }

    return routes;
  }

  get articles(): NavRoute | null {
    return this.isAdmin ? {
      label: 'Artikel',
      path: Paths.ARTICLES,
      children: [
        {
          label: 'Warengruppen',
          path: ArticlePaths['warengruppen'],
        },
        {
          label: 'Zusatzinformationen',
          path: ArticlePaths['zusatzinformationen'],
        },
        {
          label: 'Artikelvorschläge',
          path: ArticlePaths['artikelvorschläge'],
        },
      ],
    } : null;
  }

  get orders(): NavRoute {
    return {
      label: 'Aufträge',
      path: Paths.ORDERS,
      children: [
        {
          label: 'Übersicht',
          path: OrderPaths.orders,
          routerLinkActiveOptions: {
            exact: true,
          },
        },
        {
          label: 'Auftragsherkünfte',
          path: OrderPaths.orderOrigin,
        }
      ],
    };
  }

  get settings(): NavRoute | null {
    return this.isAdmin ? {
      label: 'Einstellungen',
      path: Paths.SETTINGS,
      children: [
        {
          label: 'Übersicht',
          path: SettingsPaths['uebersicht'],
          routerLinkActiveOptions: {
            exact: true,
          },
        },
        {
          label: 'Auftragskommentare',
          path: SettingsPaths['auftragskommentare'],
        },
        {
          label: 'Positionskommentare',
          path: SettingsPaths['auftragpositionskommentare'],
        },
        {
          label: 'E-Mails Auftrag Upload',
          path: SettingsPaths['emailsFailedAuftragUpload'],
        },
        {
          label: 'E-Mails Neukunden Antrag',
          path: SettingsPaths['emailsNewCustomerApplication'],
        },
        {
          label: 'Neukunden-Pdf Upload',
          path: SettingsPaths['neukundenpdf'],
        },
        {
          label: 'Interessenten Import',
          path: SettingsPaths['potentialCustomerImport'],
        },
      ],
    } : null;
  }
}
