import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';



export const CLIENT_TYPE_HEADER = 'IM-ClientType';
export const CLIENT_TYPE = 'web';

/**
 * Interceptor zur Identifikation am Backend. Sendet bei jeder Request den
 * {@link CLIENT_TYPE}-Header mit, der darauf hin im Backend ausgewertet wird,
 * um etwa die Models anders zu serialisieren o.Ä..
 */
@Injectable()
export class ClientTypeInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(
      req.clone({
        setHeaders: {
          [CLIENT_TYPE_HEADER]: CLIENT_TYPE,
        },
      }),
    );
  }
}
