import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { DetailComponentBase } from '../../../../common/layout/detail-container/DetailComponentBase';
import { Customer, CUSTOMER_ENDPOINT, showCustomerName } from '../../models/Customer';
import { DetailContainerComponent } from '../../../../common/layout/detail-container/detail-container.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiClient } from '../../../../common/api/ApiClient';
import { FromDB } from '../../../../common/api/FromDB';
import { CustomerDetailService } from '../customer-detail.service';
import { Location } from '@angular/common';

@Component({
  selector: 'sebu-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class CustomerDetailComponent extends DetailComponentBase<Customer> {
  endpoint = CUSTOMER_ENDPOINT;
  customer: FromDB<Customer>;

  @ViewChild('detailContainer') detailContainer: DetailContainerComponent<Customer>;

  showCustomerName = showCustomerName;

  constructor(
    protected api: ApiClient,
    protected toastr: ToastrService,
    protected router: Router,
    protected route: ActivatedRoute,
    detailService: CustomerDetailService,
    public location: Location,
  ) {
    super(api, toastr, router, route);

    this.customer = detailService.customer;
  }

  saveEntity() {
    alert('saving...');
  }
}
