import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppSetting } from '../models/AppSetting';
import { FromDB } from '../../../../common/api/FromDB';
import { SettingComponent } from './setting.component';
import { DATEPICKER_DE } from '../../../../config/locales';
import autobind from 'autobind-decorator';
import * as moment from 'moment';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BaseForm } from '../../../../common/forms/base-form';

export interface DateAppSetting extends AppSetting {
  type: 'date';
  value: string;
}

@Component({
  selector: 'im-string-setting',
  template: `
    <div class="input-group">
      <p-date-picker
        class="ui-fluid"
        dateFormat="dd.mm.yy"
        [placeholder]="_value"
        [lang]="de"
        [showTime]="false"
        inputStyleClass="form-control"
        (onSelect)="_value=parseDate($event)">
      </p-date-picker>
        <button class="btn btn-primary" type="button" (click)="settingChange.emit(setting)" imDisabledWhileLoading>
          Speichern
        </button>
    </div>
  `,
  styles: [],
  standalone: false,
})
export class DateSettingComponent implements SettingComponent<DateAppSetting> {

  public settingChange = new EventEmitter<FromDB<DateAppSetting>>();
  /** Die interne repräsentation des Wertes der Einstellung */
  _value: string;
  /** Die Einstellung */
  _setting: FromDB<DateAppSetting>;
  public de = DATEPICKER_DE;

  parseDate = function(date: Date) {
    return moment(date).format('DD.MM.YYYY');
  };

  /**
   * Setzt die zu verwaltende Einstellung, und verarbeitet den Stringwert in eine
   * interne Darstellung.
   *
   * @param setting
   */
  @Input()
  set setting(setting: FromDB<DateAppSetting>) {
    this._setting = setting;
    this._value = setting.value;
  }

  /**
   * Ruft das Setting mit seinem aktuellen Wert wieder ab.
   */
  get setting(): FromDB<DateAppSetting> {
    return {
      ...this._setting,
      value: this._value,
    };
  }
}
