import { Component, ViewChild } from '@angular/core';
import { OverviewContainerComponent } from '../../../common/layout/overview-container/overview-container.component';

@Component({
  selector: 'im-origin',
  template: `
    <im-overview-container heading="Auftragsherkünfte" buttonTextCreate="Neue Auftragsherkunft erstellen">
      <im-loading-error-container *imOverviewContainerOverviewTemplate>
        <im-origin-table></im-origin-table>
      </im-loading-error-container>

      <ng-container *imAdminOnly>
        <im-create-origin
          *imOverviewContainerCreationFormTemplate
          (originCreated)="onOriginCreated()"
        ></im-create-origin>
      </ng-container>
    </im-overview-container>
  `,
  standalone: false,
})
export class OriginComponent {
  @ViewChild(OverviewContainerComponent)
  overviewContainer: OverviewContainerComponent;

  onOriginCreated() {
    this.overviewContainer.switchView();
  }
}
