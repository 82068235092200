import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Customer } from '../../../../models/Customer';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ApiClient } from '../../../../../../common/api/ApiClient';
import { CUSTOMER_DOCUMENTS_ENDPOINT } from '../customer-document-endpoints';
import { FromDB } from '../../../../../../common/api/FromDB';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'sebu-customer-document-upload',
  templateUrl: 'customer-document-upload.component.html',
  standalone: false,
})
export class CustomerDocumentUploadComponent {
  @Input() customer: FromDB<Customer>;
  @Output() created = new EventEmitter();

  constructor(public api: ApiClient) { }

  public form = new UntypedFormGroup({
    'label': new UntypedFormControl(),
    'document': new UntypedFormControl(''),
  });

  submit(event) {
    event.preventDefault();

    const document = this.form.get('document');
    const attributes = new FormData();

    if (document) {
      const files = document.value as FileList;
      attributes.set('document', files[0]);
    }

    attributes.set('customer_id', this.customer.Id);
    attributes.set('label', this.form.get('label')!.value);

    this.api
      .store(CUSTOMER_DOCUMENTS_ENDPOINT, attributes)
      .pipe(tap(() => this.form.reset()))
      .subscribe(() => this.created.emit());
  }

  onFileChange(files: FileList) {
    if (!files || files.length === 0 || this.form.get('label')!.value) {
      return;
    }

    const file = files[0];
    this.form.get('label')!.patchValue(file.name);
  }
}
