<im-detail-container
 [save]="saveMessage"
 [edit]="editMessage"
 [abort]="abortEdit"
 [delete]="deleteMessage"
 [isEditable]="isEditable"
 [transformEntity]="transformMessage"
 [endpoint]="messageEndpoint"
 #detailContainer
>
  <ng-template>
    <h1 class="card-title">
      Nachricht vom {{message.activationDateTime | sbDate}}
      <small *ngIf="message.activationDateTime" class="text-muted">
        <br/>
        Erstellt am {{message.CreatedAt | sbDate}}
      </small>
      <small *ngIf="message.endDate" class="text-muted">
        <br/>
        Wird gelöscht am {{message.endDate | sbDate:true}}
      </small>
    </h1>

    <div class="form-group row">
      <label for="category" class="col-sm-2 col-form-label">Kategorie</label>
      <div class="col-sm-10">
        <p class="form-control-plaintext" *ngIf="!isEditable">
          {{message?.category?.name}}
        </p>
        <select *ngIf="isEditable"
                class="form-control form-select" id="category" [(ngModel)]="message.category.Id"
                [attr.disabled]="allCategories.length === 0 ? true : null">
          <option *ngFor="let category of allCategories" [value]="category.Id">
            {{category.name}}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row" *ngIf="isEditable">
      <label class="col-sm-2 col-form-label">
        Versenden am <span class="text-muted">(optional)</span>
      </label>
      <div class="col-sm-10">
        <p-date-picker
          class="ui-fluid" [(ngModel)]="message.activationDateTime"
          [lang]="de" dateFormat="dd.mm.yy"
          showTime="true" hourFormat="24"
        ></p-date-picker>
      </div>
    </div>

    <div class="form-group row" *ngIf="isEditable">
      <label class="col-sm-2 col-form-label">
        Löschen am <span class="text-muted">(optional)</span>
      </label>
      <div class="col-sm-10">
        <p-date-picker
          class="ui-fluid" [(ngModel)]="message.endDate"
          [minDate]="today" [lang]="de"
          dateFormat="dd.mm.yy"
        ></p-date-picker>
      </div>
    </div>

    <div class="form-group row">
      <label for="title" class="col-sm-2 col-form-label">Nachricht</label>
      <div class="col-sm-10">
        <p class="form-control-plaintext" *ngIf="!isEditable">
          {{message.title}}
        </p>
        <textarea type="text" id="title" class="form-control"
                  *ngIf="isEditable" [(ngModel)]="message.title" ></textarea>
      </div>
    </div>

    <div class="form-group row">
      <label for="Empfänger" class="col-sm-2 col-form-label">Empfänger</label>
      <div class="col-sm-10">
        <div *ngIf="!isEditable" class="list-group">
          <a class="list-group-item list-group-item-action"
             *ngFor="let user of message.users" [routerLink]="getUserLink(user)">
            {{displayUser(user)}}
          </a>
        </div>
        <div *ngIf="isEditable">
          <im-multiple-choice-list id="Empfänger" [(ngModel)]="message.users">
            <im-checkable-option *ngFor="let user of allUsers" [model]="user" class="list-group-item list-group-item-action">
              {{displayUser(user)}}
            </im-checkable-option>
          </im-multiple-choice-list>
        </div>
      </div>
    </div>
  </ng-template>
</im-detail-container>
