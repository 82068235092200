import { Component, OnInit } from '@angular/core';
import { ApiClient } from '../../../common/api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FromDB } from '../../../common/api/FromDB';

export interface FailedOrderUploadEmail {
  Id: string;
  email: string;
  first_try: boolean;
  second_try: boolean;
  third_try: boolean;
  fourth_try: boolean;
}

@Component({
  selector: 'sebu-order-comments',
  templateUrl: './emails-failed-order-upload.component.html',
  styles: [],
  standalone: false,
})
export class EmailsFailedOrderUploadComponent implements OnInit {

  public formGroupList: UntypedFormGroup = new UntypedFormGroup({});
  public newEntity: UntypedFormGroup;
  private apiEndpoint = 'failed-order-upload-email';
  public search = '';

  constructor(
    private api: ApiClient,
    protected toastr: ToastrService,
  ) {
    this.newEntity = this.createFormGroup('');
  }

  ngOnInit(): void {
    this.loadList();
  }

  add() {
    if (this.newEntity.valid) {
      this.api.store(this.apiEndpoint, this.newEntity.value).subscribe(
        () => {
          this.toastr.success('Die E-Mail wurde erfolgreich hinterlegt!');
          this.newEntity = this.createFormGroup('');
          this.loadList();
        });
    } else {
      const email = this.newEntity.get('email');
      if (email !== null) {
        email.markAsDirty();
      }
    }
  }

  changeSearch(event) {
    this.search = event.target.value;
  }

  getFormGroupListIds(): Array<string> {
    return Object.keys(this.formGroupList.controls);
  }

  delete(event) {
    const id = event.target.id;
    const formGroup = this.formGroupList.get(id);

    if (formGroup !== null) {
      if (!confirm('Sind Sie sicher, dass die Zeile gelöscht werden soll?')) {
        return;
      }
    }

    this.api.destroy(this.apiEndpoint, id).subscribe(
      () => {
        this.toastr.success('Die Zeile wurde erfolgreich gelöscht!');
        this.loadList();
      });
  }

  update(event) {
    const id = event.target.id;
    const formGroup = this.formGroupList.get(event.target.id);

    if (formGroup !== null && formGroup.valid) {
      this.api.update(this.apiEndpoint, id, formGroup.value).subscribe(
        () => {
          this.toastr.success('Der Zeile wurde erfolgreich geändert!');
          formGroup.markAsPristine();
          this.loadList();
        });
    }
  }

  loadList() {
    this.api.all<FailedOrderUploadEmail>(this.apiEndpoint).subscribe((list: FromDB<FailedOrderUploadEmail>[]) => {
        const existingIds: string[] = [];

        for (const email of list.reverse()) {
          const formGroup = this.formGroupList.get(email.Id);
          existingIds.push(email.Id);

          if (formGroup === null) {
            // Neue Einträge hinzufügen
            this.formGroupList.addControl(
              email.Id,
              this.createFormGroup(email.email, email.first_try, email.second_try, email.third_try, email.fourth_try),
            );
          } else {
            // Nur nicht geänderte Zeilen mit den neuen Daten befüllen
            if (!formGroup.dirty) {
              formGroup.reset({
                email: email.email,
                first_try: email.first_try,
                second_try: email.second_try,
                third_try: email.third_try,
                fourth_try: email.fourth_try,
              });
            }
          }
        }

        // Alle unbekannten Ids löschen
        const arrayDiff = this.getFormGroupListIds().filter(x => !existingIds.includes(x));
        for (const diff of arrayDiff) {
          this.formGroupList.removeControl(diff);
        }
      },
    );
  }

  /**
   * Erstellt den FormGroup mit Default-Daten
   *
   * @param value
   * @private
   */
  private createFormGroup(
    value: string,
    firstTry: boolean = false,
    secondTry: boolean = false,
    thirdTry: boolean = false,
    fourthTry: boolean = false,
  ): UntypedFormGroup {
    return new UntypedFormGroup({
      email: new UntypedFormControl(value, [Validators.maxLength(255), Validators.email, Validators.required, Validators.minLength(1)]),
      first_try: new UntypedFormControl(firstTry, []),
      second_try: new UntypedFormControl(secondTry, []),
      third_try: new UntypedFormControl(thirdTry, []),
      fourth_try: new UntypedFormControl(fourthTry, []),
    });
  }
}
