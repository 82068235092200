import { Component } from '@angular/core';
import { displayCategories, Item, ITEM_ENDPOINT } from '../../models/Item';
import { FromDB } from '../../../../common/api/FromDB';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'im-products-table',
  templateUrl: './products-table.component.html',
  standalone: false,
})
export class ProductsTableComponent {
  endpoint = ITEM_ENDPOINT;
  displayCategories = displayCategories;
  filters = new UntypedFormGroup({
    'category': new UntypedFormControl(),
    'product-name': new UntypedFormControl(),
  });

  displayName(item: FromDB<Item>) {
    return item.name;
  }
}
