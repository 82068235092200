import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiClient } from '../../../../common/api/ApiClient';
import { Category, CATEGORY_ENDPOINT } from '../../models/Category';
import { FromDB } from '../../../../common/api/FromDB';
import { TreeViewNode } from '../../../../common/layout/tree-view/TreeViewNode';
import { TreeViewService } from '../../../../common/layout/tree-view/tree-view.service';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { CategoriesOverviewService } from './categories-overview.service';
import { applySortOrderRecursive } from '../../../../sebu/utils/TreeUtils';

declare type CategoryTreeNode = TreeViewNode<FromDB<Category>>;

@Component({
  selector: 'im-categories-overview',
  templateUrl: './categories-overview.component.html',
  styleUrls: ['./categories-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class CategoriesOverviewComponent implements OnInit, OnDestroy {
  rootNode: CategoryTreeNode;
  subs = new Subscription();

  constructor(
    private api: ApiClient,
    private tree: TreeViewService,
    private service: CategoriesOverviewService,
  ) {
  }

  ngOnInit() {
    this.requestCategories();
    this.subs.add(this.service._refreshEvent.subscribe(() => {
      this.requestCategories();
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  requestCategories() {
    this.api
      .all<Category>(CATEGORY_ENDPOINT)
      .pipe(
        map(categories => {
          return this.tree.buildTree(categories);
        }),
      ).subscribe(root => {
      this.rootNode = applySortOrderRecursive(root);
    });
  }
}
