import { Component, Input } from '@angular/core';
import { Auftr, AUFTR_ENDPOINT, isFinished } from '../../../models/Auftr';
import { ApiClient } from '../../../../../common/api/ApiClient';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CustomerDetailService } from '../../customer-detail.service';
import { FromDB } from '../../../../../common/api/FromDB';

enum OrderStatus {
  EXPORTED,
  EXPORTING,
  FAILED,
  OPEN,
  OFFER,
}

@Component({
  selector: 'sebu-order-status',
  templateUrl: 'order-status.component.html',
  standalone: false,
})
export class OrderStatusComponent {
  @Input()
  public order: FromDB<Auftr>;
  public OrderStatus = OrderStatus;
  public pressedRetry = false;

  public get orderStatus(): OrderStatus {
    if (this.order.isOffer) {
      return OrderStatus.OFFER;
    }

    if (isFinished(this.order) && !this.order.ExportFailedAt) {
      return OrderStatus.EXPORTED;
    }

    if (this.order.ExportFailedAt) {
      return OrderStatus.FAILED;
    }

    if (! isFinished(this.order)) {
      return OrderStatus.EXPORTING;
    }

    return OrderStatus.OPEN;
  }

  constructor(
    public http: HttpClient,
    public toastr: ToastrService,
    public customerService: CustomerDetailService,
  ) { }

  /**
   * Versucht, den Auftrag neu hochzuladen.
   */
  retryUpload() {
    const retryEndpoint = `${AUFTR_ENDPOINT}/${this.order.Id}/retry-upload`;

    this.http
      .post(ApiClient.normalizeEndpoint(retryEndpoint), [])
      .subscribe(() => {
        this.pressedRetry = true;
        this.toastr.success(
          'Der Upload wird erneut versucht. Dies kann einen Moment dauern...',
          'Neustart des Uploads',
        );
        this.customerService.reload().subscribe();
      });
  }
}
