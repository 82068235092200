import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseForm } from '../../../common/forms/base-form';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FromDB } from '../../../common/api/FromDB';
import { WAREN_GR_ENDPOINT, WarenGrWithIcon } from '../models/WarenGr';
import { HttpClient } from '@angular/common/http';
import { ApiClient } from '../../../common/api/ApiClient';

export interface SortimentFormValue {
  icon: FileList;
}

@Component({
  selector: 'sebu-sortiment-form',
  template: `
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="form-group">
        <label>Icon</label>
        <div>
          <blob-image
            *ngIf="entity?.icon?.blob"
            [blob]="entity?.icon?.blob"
            style="max-width: 100%"
          ></blob-image>
          <span class="text-muted" *ngIf="!entity?.icon?.blob">
            Kein Icon vorhanden
          </span>
        </div>
      </div>

      <ng-container *ngIf="isEditable">
        <div>
          <im-file-input
            id="file" accept=".png,.bmp"
            placeholder="Icon hochladen..."
            [formControl]="icon"
            [inputClasses]="displayFieldCss('icon')"
          ></im-file-input>
          <im-field-error-display
            [displayError]="inputInvalid"
            errorMsg="Bitte ein Bild auswählen!">
          </im-field-error-display>
          <small class="form-text text-muted">
            Das Bild muss die Dimensionen 36x36px besitzen.
            Gültige Formate sind png und bmp.
          </small>
        </div>
        <div>
          <button type='button' class="btn btn-danger" (click)="deleteIcon()" imDisabledWhileLoading>
            <im-icon name="trash"></im-icon>
            Aktuelles Icon löschen
          </button>
        </div>
      </ng-container>
    </form>
  `,
  styles: [],
  standalone: false,
})
export class SortimentFormComponent extends BaseForm implements OnInit {
  @Input()
  entity: FromDB<WarenGrWithIcon>;

  @Input()
  isEditable: true;

  @Input('onSubmit')
  _onSubmit: (value: SortimentFormValue) => void;

  @Output()
  iconDeleted = new EventEmitter();
  inputInvalid: boolean = false;

  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      'icon': new UntypedFormControl(null, Validators.required),
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.inputInvalid = false;
      super.onSubmit();
      this._onSubmit(this.form.value as SortimentFormValue);
    } else {
      this.inputInvalid = true
      this.validateAllFormFields(this.form);
    }
  }

  deleteIcon() {
    if (confirm('Wollen Sie das Icon wirklich löschen? Diese Änderung wird direkt gespeichert!')) {
      const endpoint = ApiClient.normalizeEndpoint(
        WAREN_GR_ENDPOINT + '/' + this.entity.Id + '/icon',
      );

      this.http
        .delete(endpoint)
        .subscribe(() => {
          this.iconDeleted.emit();
        });
    }
  }

  get icon() {
    return this.form.get('icon');
  }

}
