import { TemplateRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { TreeViewNode } from './TreeViewNode';

@Component({
  selector: 'im-tree-view',
  template: `
  <div
    class="content"
    *ngIf="root !== null && root !== undefined && !root.isRoot"
    [ngStyle]="{ 'padding-left': paddingLeft + 'rem' }">
    <ng-container
      *ngTemplateOutlet="nodeTemplate; context: { $implicit: root }">
    </ng-container>
  </div>

  <ul class="list-group" *ngIf="root !== null && root !== undefined && root.children" [ngClass]="{
    'list-group-flush': !root.isRoot,
    'root': root.isRoot
  }">
    <li class="list-group-item" *ngFor="let child of root.children">
      <im-tree-view [root]="child" [nodeTemplate]="nodeTemplate"></im-tree-view>
    </li>
  </ul>
  `,
  styleUrls: [ './tree-view.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class TreeViewComponent {
  @Input() root: TreeViewNode;
  @Input() nodeTemplate: TemplateRef<any>;

  basePaddingLeft = 1.25; // rem, von Bootstrap $list-group-item-padding-horizontal übernommen
  paddingLeftPerLevel = 1.5; // rem

  get paddingLeft() {
    if (!this.root || !this.root.level) {
      return this.basePaddingLeft;
    }

    return this.basePaddingLeft + this.root.level * this.paddingLeftPerLevel;
  }
}
