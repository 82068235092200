import { Component } from '@angular/core';
import { AuthService } from '../../im-modules/user/auth/auth.service';
import { hasRole } from '../../im-modules/user/users/User';
import { Roles } from '../../im-modules/user/users/Role';

@Component({
  selector: 'sebu-settings',
  template: `
      <im-app-settings
              *ngIf="isSuperAdmin"
              title="App Einstellungen"
      ></im-app-settings>
      <im-backend-settings
              *ngIf="isSuperAdmin"
              title="Backend Einstellungen"
      ></im-backend-settings>
    <sebu-imprint></sebu-imprint>
    <sebu-data-protection></sebu-data-protection>
  `,
  styles: [],
  standalone: false,
})
export class SettingsComponent {
  isSuperAdmin = false;

  constructor(
    private auth: AuthService,
  ) {
    this.isSuperAdmin = hasRole(auth.user, Roles.SUPER_ADMIN);
  }
}
