import { Component, Input } from '@angular/core';

@Component({
  selector: 'sebu-customer-rest-days',
  template: `
    <table cellspacing="0" cellpadding="0" width="100%">
      <thead>
      <tr>
        <th *ngFor="let day of days" [width]="1 / days.length * 100 + '%'" class="text-center">
          {{ day }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td *ngFor="let _ of days; let i = index" class="text-center">
          <input type="checkbox" disabled [value]="isRestDay(i)"/>
        </td>
      </tr>
      </tbody>
    </table>
  `,
  standalone: false,
})
export class CustomerRestDaysComponent {
  /**
   * Besuchsruhetage je 1 Stelle für: Mo, Di, Mi, Do, Fr, Sa
   * 0 = kein Ruhetag
   * 1 = Ruhetag
   */
  @Input() restDays: string;
  days = [
    'Mo',
    'Di',
    'Mi',
    'Do',
    'Fr',
    'Sa',
    'So',
  ];

  isRestDay(day: number) {
    if (day === this.days.length - 1) {
      // Sonntag funktioniert vorerst nicht
      return false;
    }

    return String(this.restDays)[day] === '1';
  }
}
