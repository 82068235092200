import { Component, Input } from '@angular/core';
import { FromDB } from '../../../../../common/api/FromDB';
import * as moment from 'moment';
import { ContactWithPosition } from '../../../models/Contact';

@Component({
  selector: 'sebu-contact-detail',
  styleUrls: ['contact-detail.component.scss'],
  templateUrl: 'contact-detail.component.html',
  standalone: false,
})
export class ContactDetailComponent {
  @Input() contact: FromDB<ContactWithPosition> | undefined | null;
  @Input() mainContact: FromDB<ContactWithPosition> | undefined | null;

  get isMainContact() {
    return this.contact && this.mainContact
      && this.contact.Id === this.mainContact.Id;
  }

  displayAge() {
    if (!this.contact) {
      return '';
    }

    const birthDate = moment(this.contact.birthDate);

    if (!birthDate.isValid()) {
      return '';
    }

    return moment().diff(birthDate, 'years');
  }

  get avatar() {
    const isFemale = this.contact !== null
      && this.contact !== undefined
      && this.contact.salutation
      && this.contact.salutation.salutation.toLocaleLowerCase() === 'frau';

    return isFemale
      ? 'user_female_circle.svg'
      : 'user_male_circle.svg';
  }
}
