import { Component, Input } from '@angular/core';
import { TreeViewNode } from '../../../../common/layout/tree-view/TreeViewNode';
import { Category } from '../../models/Category';
import { FromDB } from '../../../../common/api/FromDB';

@Component({
  selector: 'im-categories-overview-node',
  template: `
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <span>{{ node.data.sortOrder }}: {{ node.data.name }}</span>
        <img src="assets/invisible.svg" height="30px" width="30px" alt="invisible" class="avatar ms-1" *ngIf="!node.data.is_visible">
      </div>
      <button imDetailButton [model]="node.data">
        Details
      </button>
    </div>
  `,
  standalone: false,
})
export class CategoriesOverviewNodeComponent {
  @Input() node: TreeViewNode<FromDB<Category>>;
}
