import { Component, ViewChild } from '@angular/core';
import { DetailComponentBase } from '../../../../common/layout/detail-container/DetailComponentBase';
import { Item, ITEM_ENDPOINT } from '../../models/Item';
import { ToastrService } from 'ngx-toastr';
import { ApiClient } from '../../../../common/api/ApiClient';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductFormComponent } from '../product-form/product-form.component';
import autobind from 'autobind-decorator';
import { DetailContainerComponent } from '../../../../common/layout/detail-container/detail-container.component';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'im-product-detail',
  templateUrl: './product-detail.component.html',
  standalone: false,
})
export class ProductDetailComponent extends DetailComponentBase<Item> {
  @ViewChild('detailContainer') detailContainer: DetailContainerComponent<Item>;
  endpoint = ITEM_ENDPOINT;

  @ViewChild(ProductFormComponent) productForm: ProductFormComponent;

  constructor(
    protected api: ApiClient,
    protected toastr: ToastrService,
    protected router: Router,
    protected route: ActivatedRoute,
  ) {
    super(api, toastr, router, route);
  }

  @autobind
  abort() {
    this.abortEdit();
    this.productForm.fileHasChanged = false;
    this.productForm.form.markAsUntouched();
    (this.productForm.file as AbstractControl).reset();
  }

  @autobind
  public onEntityLoaded() {
    if (this.productForm) {
      // Bild anzeigen, sobald korrekte Entität geladen ist
      this.productForm.fileHasChanged = false;
    }
  }

  @autobind
  submitForm() {
    const formValue = this.productForm.getDataToSubmit();

    // Api nimmt an, dass falls eine Datei mitgeschickt wird, diese auch eine
    // richtige Datei ist. Nicht gesetzte Werte sollten also im Frontend
    // herausgefiltert werden!
    if (formValue.thumbnailFile === undefined) {
      delete formValue.thumbnailFile;
    }

    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      formData.append(key, formValue[key]);
    }

    // Da sonst Laravel keine Formdata im PUT akzeptiert
    formData.append('_method', 'PUT');

    this.api.store(ITEM_ENDPOINT + '/' + this.entity.Id, formData)
      .subscribe((newItem) => {
        this.toastr.success('Produkt erfolgreich aktualisiert!');
        this.isEditable = false;
        this.detailContainer.refresh();
        this.productForm.form.markAsUntouched();
      });
  }

  @autobind
  public saveEntity() {
    this.productForm.onSubmit();
  }
}
