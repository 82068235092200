import { Component, ViewChild } from '@angular/core';
import { DetailComponentBase } from '../../../../common/layout/detail-container/DetailComponentBase';
import {CONTACT_POSITION_UNKNOWN, CONTACT_POSITIONS_ENDPOINT, ContactPosition} from '../../models/ContactPosition';
import { DetailContainerComponent } from '../../../../common/layout/detail-container/detail-container.component';
import { ApiClient } from '../../../../common/api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import autobind from 'autobind-decorator';
import { CustomerContactPositionFormComponent } from '../form/customer-contact-position-form.component';

@Component({
  selector: 'sebu-custom-contact-position-detail',
  templateUrl: 'customer-contact-position-detail.component.html',
  styles: [],
  standalone: false,
})
export class CustomerContactPositionDetailComponent extends DetailComponentBase<ContactPosition> {
  @ViewChild(DetailContainerComponent)
  detailContainer: DetailContainerComponent;

  @ViewChild(CustomerContactPositionFormComponent)
  form: CustomerContactPositionFormComponent;

  endpoint = CONTACT_POSITIONS_ENDPOINT;

  constructor(protected api: ApiClient,
              protected toastr: ToastrService,
              protected router: Router,
              protected route: ActivatedRoute) {
    super(api, toastr, router, route);
  }

  @autobind
  triggerSubmit() {
    this.form.onSubmit();
  }

  @autobind
  send(data: ContactPosition) {
    this.api
      .update(this.endpoint, this.entity.Id, data)
      .subscribe(() => {
        this.form.onSuccessfulSubmit('Position erfolgreich bearbeitet!');
        this.isEditable = false;
        this.detailContainer.refresh();
      });
  }

  protected readonly CONTACT_POSITION_UNKNOWN = CONTACT_POSITION_UNKNOWN;

  get isUnknownPosition() {
    return this.entity?.position === CONTACT_POSITION_UNKNOWN;
  }
}
